import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Batch, BATCH_STATUS, FileInfo, makeDynamicUploadRequest, makeRequest, Organization, Process } from "../api";
import { OrgContext } from "../OrgContext";
import { UserContext } from "../UserContext";
import { FlexRow, getFileDownloadUrl, InfoTip, LoadingIndicator, MarkdownTip } from "./Utils";

export const generateEmbedLink = (batch: Batch, org: Organization | null) => {
    let origin = "https://apply.upliftcodecamp.com"
    if (org?.fullDomain) {
        origin = "https://" + org.fullDomain;
    }

    return origin + `/embed.html?batchId=${batch._id}`;
};

const NEW_BATCH: Batch = {
    name: "",
    description: "",
    slug: "",
    privateDetails: "",
    status: "Draft",

    // Unused or deprecated
    _id: "",
    assessmentIds: [],
    capacity: 20,

    iconFile: null,
    bannerFile: null,
};

interface Props {
    batch: Batch | null;
    isAdding?: boolean;
    /** Called after a batch is created or edited */
    onChange: () => void;
    /** Called if the menu is closed without changes */
    onClose: () => void;
}
export const EditBatchForm = (props: Props) => {
    const user = React.useContext(UserContext)?.user;
    const batch = props.batch || { ...NEW_BATCH };
    const org = React.useContext(OrgContext);
    const [name, setName] = React.useState(batch.name);
    const [description, setDescription] = React.useState(batch.description);
    const [status, setStatus] = React.useState(batch.status || "Draft");
    const [slug, setSlug] = React.useState(batch.slug || "");
    const [processId, setProcessId] = React.useState(batch.processId || "");
    const [isLoading, setIsLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [enableEmbedding, setEnableEmbedding] = React.useState(batch.enableEmbedding);

    const [bannerUpload, setBannerUpload] = React.useState<any>(null);
    const [iconUpload, setIconUpload] = React.useState<any>(null);

    const [bannerFile, setBannerFile] = React.useState<FileInfo>(batch.bannerFile);
    const [iconFile, setIconFile] = React.useState<FileInfo>(batch.iconFile);

    const [processes, setProcesses] = React.useState<Process[] | null>();
    React.useEffect(() => {
        makeRequest("/api/processConfigBlobs", "GET").then(blobs => {
            setProcesses(blobs.map(b => {
                const parsed = JSON.parse(b.json);
                parsed._id = b._id;
                return parsed;
            }));
        });
    }, []);

    const updateBatch = async () => {
        const orgId = batch.organizationId || (org !== "NONE" && org?._id);
        if (!validateFields() || !orgId) {
            return;
        }

        setIsLoading(true);
        const data: any = {
            name, description, privateDetails: batch.privateDetails, slug, capacity: batch.capacity, status,
            organizationId: orgId,
            processId,
            bannerFile, iconFile, enableEmbedding
        };
        try {
            if (bannerUpload) {
                const fileInfo = await makeDynamicUploadRequest(user?.email, "batchBanner", bannerUpload);
                data.bannerFile = fileInfo;
            }
            if (iconUpload) {
                const iconInfo = await makeDynamicUploadRequest(user?.email, "batchIcon", iconUpload);
                data.iconFile = iconInfo;
            }

            const route = props.isAdding ? "/api/batches" : `/api/batches/${batch._id}`;
            const method = props.isAdding ? "POST" : "PUT";
            await makeRequest(route, method,
                data);
            props.onChange();
        } catch (ex) {
            alert("Error occurred saving changes to batch: " + ex.message);
        }
        setIsLoading(false);
    };

    const validateFields = () => {
        if (slug && (!slug.match(/^[a-z0-9\\-]+$/) || slug.length < 6)) {
            setErrorMessage("Slug can only contain lower case a-z, digits 0-9 and the character '-'. Must be at least 6 characters long.");
            return false;
        }

        return true;
    };

    return (<div>
        <div>
            <label>Batch Name</label>
            <input type="text" value={name} onChange={e => setName(e.target.value)} />
        </div>
        <div>
            <label>
                <MarkdownTip />
                <br />
                Description/details
            </label>
            <textarea rows={6} cols={80} value={description} onChange={e => setDescription(e.target.value)} />
        </div>
        <div>
            <label>
                Batch URL id
                <InfoTip tip="Optional. Allows you to customize the URL for this entry. Only allows lower case a-z, digits 0-9 and the '-' character. Must be at least 6 characters long." />
            </label>
            <input type="text" value={slug} onChange={e => setSlug(e.target.value)} />
        </div>
        <div>
            <label>
                Batch Status
                <InfoTip tip="'Draft': new, won't show. 'Open': Accepting applications. 'Closed': Only current applicants can continue. 'Complete': All applicant activity done." />
            </label>
            <select value={status} onChange={e => setStatus(e.target.value as any)}>
                {BATCH_STATUS.map(s => <option value={s} key={s}>{s}</option>)}
            </select>
        </div>
        <div>
            <label>Process</label>
            <select value={processId} onChange={e => setProcessId(e.target.value)}>
                <option value=""></option>
                {processes?.map((p, index) => <option value={p._id} key={p._id}>
                    {p.label || "Unlabeled process " + index}
                </option>)}
            </select>
        </div>
        <br />

        <h4>Embedding</h4>
        <div>
            <label>
                Embeddable{" "}
                <InfoTip tip="Optional. Allows you to embed this URL inside an iframe to show the application form on your own website." />
                <input type="checkbox" checked={enableEmbedding}
                    onChange={e => setEnableEmbedding(e.target.checked)} />
            </label>
        </div>
        {enableEmbedding && (<div>
            {batch._id ? <code>
                {generateEmbedLink(batch, org === "NONE" ? null : org)}
            </code> : <span>Check back after saving.</span> }
            
        </div>)}
        <br />

        <h4>Branding</h4>
        <FlexRow>
            <div>
                <label>Batch Icon (square)</label>
                <input type="file" onChange={e => setIconUpload(e.target.files[0])} />
            </div>
            <div style={{ padding: "20px" }}>
                {iconFile && <img alt="org icon" src={getFileDownloadUrl(iconFile)} style={{ height: "60px" }} />}
                {iconFile && <button className="secondary inline" onClick={() => setIconFile(null)}>
                    <FontAwesomeIcon icon="times" />
                </button>}
            </div>
        </FlexRow>

        <FlexRow>
            <div>
                <label>Banner image (2:1 horizontal rectangle)</label>
                <input type="file" onChange={e => setBannerUpload(e.target.files[0])} />
            </div>
            <div style={{ padding: "20px" }}>
                {bannerFile && <img alt="org logo" src={getFileDownloadUrl(bannerFile)} style={{ height: "100px" }} />}
                {bannerFile && <button className="secondary inline" onClick={() => setBannerFile(null)}>
                    <FontAwesomeIcon icon="times" />
                </button>}
            </div>
        </FlexRow>

        { errorMessage && (<div style={{ color: "red" }}>{errorMessage}</div>)}
        <div style={{ marginTop: "20px" }}>
            <button disabled={isLoading} type="button" onClick={updateBatch}>
                {isLoading && <LoadingIndicator />}
                Save changes
            </button>
            <button disabled={isLoading} className="secondary" type="button" onClick={props.onClose}>Cancel</button>
        </div>
    </div >);
};