import React from "react";
import { makeRequest, SignupLink, User } from "../../common/api";
import { DataTable } from "../../common/components/DataTable";
import { justConfirm } from "../../common/components/Modals";
import { LoadingIndicator, LoadingPage, PageTitle, useTitle } from "../../common/features/Utils";
import { OrgContext } from "../../common/OrgContext";
import { UserContext } from "../../common/UserContext";

// TODO: Handle users who may have multiple memberships in the same org (unintended)
const getHighestRoleForCurrentOrg = (member: User, orgId: string) => {
    return member?.orgRoles?.filter(r => r.orgId === orgId)?.[0]?.role;
}

export const Users = () => {
    useTitle("Users and Roles");
    const user = React.useContext(UserContext)?.user;
    const org = React.useContext(OrgContext);

    const [members, setMembers] = React.useState<User[] | null>(null);
    const [pendingMembers, setPendingMembers] = React.useState<SignupLink[] | null>(null);
    const fetchData = () => {
        setIsLoading(true);
        makeRequest("/api/users?filter=viewer", "GET").then((users: User[]) => {
            setMembers(users);
            setIsLoading(false);
        });
        makeRequest("/api/signupLinks", "GET").then(setPendingMembers);
    };

    React.useEffect(fetchData, []);

    const [email, setEmail] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [selectedRole, setSelectedRole] = React.useState("");



    const onAddUser = () => {
        if (!email || !selectedRole || isLoading || !org || org === "NONE") {
            return; // no-op return
        }
        setIsLoading(true);
        setErrorMessage("");
        makeRequest("/api/addUserToOrg", "POST", { orgId: org._id, email, role: selectedRole }).then(
            result => {
                fetchData();
                setEmail("");
                setSelectedRole("");
                alert(result.message);
            },
            err => {
                setErrorMessage(err.message);
                setIsLoading(false);
            }
        )
    }

    const onChangeRole = async (member: User, newRole: string) => {
        if (!newRole || !org || org === "NONE") {
            return;
        }
        try {
            if (newRole === "Remove") {
                await justConfirm(`Are you sure you want to remove ${member.email} from the organization?`)
                setIsLoading(true);
                await makeRequest("/api/removeMemberFromOrg", "POST", { orgId: org._id, userId: member._id });
                fetchData();
                return;
            } else {
                if (newRole === "Owner") {
                    await justConfirm(`Are you sure you want to make ${member.email} an Owner of your organization? This is an irreversible operation`);
                }
                setIsLoading(true);
                await makeRequest("/api/changeRoleInOrganization", "POST", { orgId: org._id, userId: member._id, role: newRole });
                fetchData();
            }
        } catch (ex) {
            setErrorMessage("Something went wrong modifying roles: " + ex.message);
        }
    }

    if (!org || org === "NONE") {
        return <LoadingPage delayedMessage="If this page does not load, make sure you have admin permissions on the Organization associated with this domain." />;
    }

    const columns = [
        { label: "Email", key: "email" },
        { label: "Role", renderer: m => getHighestRoleForCurrentOrg(m, org._id) },
    ];

    return (
        <div>
            <PageTitle>Org Users</PageTitle>
            <p>Manage your organization's users here.</p>
            {!members && !errorMessage && <LoadingIndicator />}
            {members &&
                <DataTable
                    data={members}
                    columns={columns}
                    canExpand={true}
                    renderExpandedRow={(data, index) => isLoading ? <LoadingIndicator /> : <RoleEditor
                        member={data}
                        orgId={org._id}
                        onChangeRole={onChangeRole} />} />
            }
            <br /><br />
            <h4>Add a new user:</h4>
            <div>
                <label>Email</label>
                <input type="text" name="email" required
                    value={email}
                    disabled={isLoading}
                    onChange={(e) => setEmail(e.target.value)} />
                <br />
                <label>Role</label>
                <select value={selectedRole} onChange={e => setSelectedRole(e.target.value)}>
                    <option value=""></option>
                    <option value="Owner">Owner</option>
                    <option value="Admin">Admin</option>
                    <option value="Viewer">Viewer</option>
                </select>
                <br />
                {errorMessage && (<div style={{ color: "red" }}>{errorMessage}</div>)}
                <button disabled={isLoading} onClick={onAddUser}>
                    {isLoading && <LoadingIndicator />}{" "}
                    Add
                </button>
                <br />
                <br />
                <p>
                    Add the user to your organization. If their email is not yet registered on apply.upliftcodecamp.com, we'll send
                    them an email with a signup link. They will need to use that signup link in order to join your
                    organization.
                </p>

                <br />
                {
                    user && pendingMembers && (pendingMembers.length === 0 ? (
                        <div>You have no outstanding membership invites</div>
                    ) : (
                        <div>
                            <h4>Pending invites</h4>
                            <DataTable data={pendingMembers} columns={[
                                { label: "Email", key: "email" },
                                { label: " Role", key: "roleToUse" }, {
                                    label: "Link", renderer: (row) => `https://apply.upliftcodecamp.com/signup?token=${row.token}`
                                }]}
                                onRowDelete={row => {

                                }}
                            />
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

interface RoleEditorProps {
    member: User;
    orgId: string;
    onChangeRole: (member: User, newRole: string) => void
}
const RoleEditor = (props: RoleEditorProps) => {
    const [selectedRole, setSelectedRole] = React.useState("");

    const currentRole = getHighestRoleForCurrentOrg(props.member, props.orgId);
    if (currentRole === "Owner") {
        return <div>{props.member.email} is an Owner of this org. You cannot change an Owner's roles.</div>;
    }
    return <div>
        <label>Change role</label>
        <select value={selectedRole} onChange={e => setSelectedRole(e.target.value)}>
            <option value=""></option>
            <option value="Owner">Owner</option>
            <option value="Admin">Admin</option>
            <option value="Viewer">Viewer</option>
            <option value="Remove">Remove from organization</option>
        </select>
        <button className="secondary inline" onClick={() => props.onChangeRole(props.member, selectedRole)}>Confirm changes</button>
    </div>
}