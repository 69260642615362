import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DOMPurify from "dompurify";
import marked from "marked";
import React from "react";
import styled from "styled-components";
import { OrgContext } from "../OrgContext";
import { UserContext } from "../UserContext";
import {
  DynamicFieldConfig,
  DynamicFormConfig,
  makeDynamicUploadRequest,
  makeRequest,
} from "../api";
import { AttachmentLink, Required } from "./Utils";

const DEFAULT_VALUES: any = {
  text: "",
  email: "",
  paragraph: "",
  tel: "",
  file: undefined,
};

const getDefaultEntry = (
  currentEntry: any,
  initialEntry: any,
  configuration: DynamicFormConfig
) => {
  const entry: any = { ...initialEntry };
  Object.keys(currentEntry).forEach((key) => {
    entry[key] = initialEntry[key] || currentEntry[key]; // Avoid overwriting with an empty value
  });
  configuration.fields.forEach((f) => {
    entry[f.name] =
      initialEntry[f.name] || entry[f.name] || DEFAULT_VALUES[f.dataType];
    console.log(
      `Entry ${f.name} updated to ${
        entry[f.name]
      } based on initialEntry or default value`
    );
  });

  return entry;
};

interface FormProps {
  /** Callback executed after an application is submitted */
  onSubmitted: (entry: any) => void;

  /** Set to true to prevent automatic userId/email binding, for admin use cases. */
  isAnonymous?: boolean;

  /** Makes it possible to display the entire form in a disabled manner. */
  disabled?: boolean;

  configuration: DynamicFormConfig;

  /** Forms the initial values for the entry. */
  initialEntry: any;

  preprocess?: (data: any) => any;

  /** If provided, will render a close button. */
  onClose?: () => void;
}
export const DynamicForm = (props: FormProps) => {
  const org = React.useContext(OrgContext);
  const { configuration } = props;

  const [entry, setEntry] = React.useState<any>({});
  React.useEffect(() => {
    const newEntry = getDefaultEntry(entry, props.initialEntry, configuration);
    setEntry(newEntry);
    console.log(newEntry);
    // Avoid a warning becasue I don't want to rerun this effect when entry changes
    // eslint-disable-next-line
  }, [props.initialEntry, configuration, setEntry]);

  const [uploads, setUploads] = React.useState<any>({});
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const user = React.useContext(UserContext)?.user;

  const validateFields = () => {
    setErrorMessage("");
    for (let field of configuration.fields) {
      if (field.required && !entry[field.name]) {
        setErrorMessage("Please complete all required fields");
        return false;
      }
    }
    return true;
  };

  const onSubmit = async () => {
    if (!validateFields()) {
      return;
    }

    setIsSubmitting(true);

    // Tries to assign an email to the upload, but if that
    // doesn't exist, we'll upload anyways.
    const uploader = entry["email"] || (!props.isAnonymous && user?.email);
    for (let key of Object.keys(uploads)) {
      if (!entry[key]) {
        try {
          const fileInfo = await makeDynamicUploadRequest(
            uploader,
            key,
            uploads[key],
            org && org !== "NONE" && org._id
          );
          entry[key] = fileInfo;
        } catch (ex) {
          setErrorMessage(
            `Failed to upload '${key}': ${uploads[key].fileName}. Please upload a choose a valid file and try again. Also check that the file size is under 2MB.`
          );
          setIsSubmitting(false);
          return;
        }
      }
    }

    try {
      // Technically, entry has been mutated at this point locally which would be smashed
      // on the next render, but the variable reference is bound to this scope, so
      // it is safe to modify and use within this block.
      const route = configuration.route.replace(":entryId", entry._id);
      const finalEntry = props.preprocess ? props.preprocess(entry) : entry;
      const submittedEntry = await makeRequest(
        route,
        configuration.method,
        finalEntry
      );
      setIsSubmitting(false);
      props.onSubmitted(submittedEntry);
    } catch (ex) {
      setErrorMessage("Failed to submit: " + ex.message);
      setIsSubmitting(false);
    }

    // Then add those fileIds to the upcoming application
  };

  return (
    <div>
      {/* {configuration.title && (<h3 style={{ marginBottom: "20px" }}>
      {configuration.title}
    </h3>)} */}
      {configuration.fields.map((field) => (
        <DynamicApplyField
          fieldConfig={field}
          value={entry[field.name]}
          key={field.name}
          disabled={props.disabled}
          onChange={(value: any) => {
            if (field.dataType === "file") {
              const newUploads = { ...uploads };
              newUploads[field.name] = value;
              entry[field.name] = undefined; // Ensure that the entry is clear to allow re-uploads in case of a failure
              setUploads(newUploads);
            } else {
              const newEntry = { ...entry };
              newEntry[field.name] = value;
              setEntry(newEntry);
            }
          }}
        />
      ))}

      <FormRow>
        <div style={{ color: "red" }}>{errorMessage}</div>
        <label></label>
        <button
          type="button"
          style={{ marginTop: "10px" }}
          onClick={onSubmit}
          disabled={isSubmitting}
        >
          {isSubmitting && <FontAwesomeIcon icon="circle-notch" spin={true} />}
          Submit
        </button>
        {props.onClose && (
          <button
            className="secondary"
            style={{ marginLeft: "20px" }}
            onClick={props.onClose}
            disabled={isSubmitting}
          >
            Cancel
          </button>
        )}
      </FormRow>
    </div>
  );
};

const FormRow = styled.div`
  margin-bottom: 10px;
`;

interface FieldProps {
  fieldConfig: DynamicFieldConfig;
  value: any;
  onChange: (value: any) => void;
  disabled?: boolean;
}
export const DynamicApplyField = (props: FieldProps) => {
  const dataType = props.fieldConfig.dataType;

  const renderDetails = () => {
    if (!props.fieldConfig.details) {
      return null;
    }
    const sanitizedHtml = DOMPurify.sanitize(
      marked(props.fieldConfig.details || "")
    );

    return <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }}></div>;
  };

  const renderInputElement = () => {
    if (dataType === "file") {
      return (
        <>
          <input
            id={props.fieldConfig.name}
            type={"file"}
            onChange={(e) => props.onChange(e.target.files![0])}
            disabled={props.disabled}
            required={props.fieldConfig.required}
          />
          {props.value && (
            <span>
              <FontAwesomeIcon icon="check-square" style={{ color: "#4B4" }} />
              <AttachmentLink file={props.value} />
            </span>
          )}
        </>
      );
    } else if (dataType === "paragraph") {
      return (
        <textarea
          id={props.fieldConfig.name}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
          disabled={props.disabled}
          required={props.fieldConfig.required}
          style={{ width: "250px", maxWidth: "100%", height: "100px" }}
        />
      );
    } else {
      return (
        <input
          id={props.fieldConfig.name}
          type={dataType}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
          disabled={props.disabled}
          required={props.fieldConfig.required}
        />
      );
    }
  };

  return (
    <FormRow>
      <label htmlFor={props.fieldConfig.name}>
        {props.fieldConfig.label}
        {props.fieldConfig.required && <Required />}
      </label>
      <div style={{ display: "inline-block", maxWidth: "450px" }}>
        {renderDetails()}
        {renderInputElement()}
      </div>
    </FormRow>
  );
};
