import React from "react";
import { makeRequest, PaymentAccount } from "../common/api";
import { PageBody } from "../common/components/PageBody";
import { getYearMonthDate, ListItem, LoadingPage } from "../common/features/Utils";

export const PaymentsPage = () => {
  const [accounts, setAccounts] = React.useState<PaymentAccount[] | null>(null);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [selectedAccount, setSelectedAccount] = React.useState<PaymentAccount | null>(null);

  React.useEffect(() => {
    makeRequest("/api/myPaymentAccounts", "GET").then(data => {
      setAccounts(data);
    }, err => {
      setErrorMessage(err.message);
    })
  }, []);

  if (accounts === null) {
    if (errorMessage) {
      return <PageBody>
        {errorMessage && (<div style={{ color: "red" }}>{errorMessage}</div>)}
      </PageBody>
    } else {
      return <LoadingPage />;
    }
  }

  if (accounts.length === 1) {
    return <PageBody>
      <PaymentAccountDetails account={accounts[0]} />
    </PageBody>
  }

  return <PageBody>
    {!selectedAccount && <>
      <h4>Your payment accounts</h4>
      {accounts.map(acc =>
        <ListItem style={{ cursor: "pointer" }} onClick={() => setSelectedAccount(acc)}>
          <b>Balance: {acc.totalBalance} {acc.currency}</b>
        </ListItem>
      )}
    </>}
    {selectedAccount && <>
      <PaymentAccountDetails account={selectedAccount} />
      <br /><br />
      <button className="secondary" onClick={() => setSelectedAccount(null)}>Done</button>
    </>}
  </PageBody>
};

interface Props {
  account: PaymentAccount;
}
export const PaymentAccountDetails = (props: Props) => {
  const upcomingInvoices = props.account.invoices.filter(inv => new Date(inv.dueDate) > new Date() && !inv.isComplete);
  const pastDueInvoices = props.account.invoices.filter(inv => new Date(inv.dueDate) < new Date() && !inv.isComplete);
  return <>
    <h3>Your account details</h3>
    <h5>Summary</h5>
    <div>
      <b>Balance: </b>
      {props.account.totalBalance} {props.account.currency}
    </div>
    {pastDueInvoices.length > 0 &&
      <div style={{ padding: "20px", borderRadius: "10px", backgroundColor: "#FEE", border: "1px solid #A11" }}>
        <h4>Overdue Payments</h4>
        {pastDueInvoices.slice(0, 3).map(inv => <div>
          {inv.dueAmount} {inv.currency} on {getYearMonthDate(inv.dueDate)}
        </div>)}
      </div>}

    <h5>Due soon</h5>
    <div>
      {upcomingInvoices.slice(0, 3).map(inv => <div>
        {inv.dueAmount} {inv.currency} on {getYearMonthDate(inv.dueDate)}
      </div>)}
    </div>
  </>
}