export interface FacetOptions {
    excludeKeys?: string[];
    maxDepth?: number;

    // Erases any keys where the number of unique entries is greater than the
    // given percent. So if this value is 0.5, you cannot have more than 50 unique
    // entries out of 100 total items.
    // Default: 0.2
    maxUniqueness?: number;

    // Note that singleton values are always excluded

    valueMappings?: any;
}

export interface IndexFacet {
    index?: number;

    value: string | boolean;
    type?: "Value" | "Existence";
}

interface FacetData {
    index: number;
    valueCounts: Map<string, number>;
    type: "Value" | "Existence" | "None";

    // How many rows have a value
    populatedCount: number;
    // How many rows are analyzed overall
    totalCount: number;
}

export interface RenderedData {
    textContent: string;
    node?: React.ReactNode;
    style?: React.CSSProperties;
  }
export interface ValueEntry {
    id: number;
    rowData: any;
    renderedValues: RenderedData[];
}
export type ValueTable = ValueEntry[];

export const filterValueTableByFacets = (unfilteredTable: ValueTable, selectedFacets: IndexFacet[]) => {
    return unfilteredTable.filter((valueTableEntry) => {
        return selectedFacets.every((facet) => {
          if (facet.type === "Value") {
            return valueTableEntry.renderedValues[facet.index].textContent === facet.value;
          } else if (facet.type === "Existence") {
            return !!valueTableEntry.renderedValues[facet.index].textContent === facet.value;
          }
          return false;
        });
      });
};

// Given a list of selected facets, compute value counts for changing the selected facet
export const buildValueTableSubfacetMap = (unfilteredTable: ValueTable, selectedFacets: IndexFacet[]): FacetData[] => {
    const subfacetData = [];
    for (let facet of selectedFacets) {
        const filteredTable = filterValueTableByFacets(unfilteredTable, selectedFacets.filter((f) => f !== facet));
        const facetData = buildSingleFacetData(filteredTable, facet.index);
        subfacetData.push(facetData);
    }

    return subfacetData;
}

export const buildSingleFacetData = (table: ValueTable, index: number): FacetData => {
    const facetData = {
        index: index,
        valueCounts: new Map<string, number>(),
        populatedCount: 0,
        totalCount: 0,
        type: "Value"
    } as FacetData;

    for (let entry of table) {
        const values = entry.renderedValues;
        const text = values[index].textContent;
        const counts = facetData.valueCounts;
        if (counts.has(text)) {
            counts.set(text, counts.get(text) + 1);
        } else {
            counts.set(text, 1);
        }
        if (text || values[index].node) {
            facetData.populatedCount++;
        }
        facetData.totalCount++;
    }
    if (facetData.populatedCount < facetData.valueCounts.size * 1.5) {
        facetData.type = "Existence";
    }
    return facetData;
};

// Build an unfiltered list of all the counts of all the values in the data per column
export const buildValueTableFacetMap = (table: ValueTable): FacetData[] => {
    if (table.length === 0 || table[0]?.renderedValues.length === 0) {
        return [];
    }

    const facetList = table[0].renderedValues.map((_, i) => ({
        index: i,
        valueCounts: new Map<string, number>(),
        populatedCount: 0,
        totalCount: 0,
        type: "Value"
    } as FacetData));
    

    for (let entry of table) {
        const values = entry.renderedValues;
        for (let i = 0; i < values.length; i++) {
            const text = values[i].textContent;
            const counts = facetList[i].valueCounts;
            if (counts.has(text)) {
                counts.set(text, counts.get(text) + 1);
            } else {
                counts.set(text, 1);
            }
            if (text || values[i].node) {
                facetList[i].populatedCount++;
            }
            facetList[i].totalCount++;
        }
    }

    for (let facet of facetList) {
        if (facet.populatedCount < facet.valueCounts.size * 1.5) {
            facet.type = "Existence";
        }
    }

    return facetList;
}

export const buildFacetMap = (data: any[], options: FacetOptions = {}): Map<string, KeyMetadata> => {
    if (options.maxUniqueness === undefined) {
        options.maxUniqueness = 0.2;
    }
    const facetMap = new Map<string, KeyMetadata>();

    for (let item of data) {
        handleItem(item, "", facetMap);
    }

    postProcess(data, facetMap, options);

    return facetMap;
}

const postProcess = (data: any[], facetMap: Map<string, KeyMetadata>, options: FacetOptions) => {
        for (let key of Array.from(facetMap.keys())) {
            if (options.maxUniqueness !== undefined && facetMap.get(key).valueCounts.size > options.maxUniqueness * data.length) {
                facetMap.delete(key);
            } else if (options?.excludeKeys?.includes(key) || options?.excludeKeys?.includes(key.split(".")[0])) {
                facetMap.delete(key);
            } else if (facetMap.get(key).valueCounts.size <= 1) {
                facetMap.delete(key);
            } else if (options.valueMappings?.[key]) {
                // const mapper = options.valueMappings?.[key];
                // const map = facetMap.get(key);
                // TODO: Replace each entry key with the mapped key
            }
        }

        for (let key of Array.from(facetMap.keys())) {
            const parts = key.split(".");
            if (parts.length > 0) {
                for (let i = 0; i < parts.length - 1; i++) {
                    const newKey = parts.slice(0, i + 1).join(".");
                    if (options?.excludeKeys?.includes(newKey)) {
                        facetMap.delete(key);
                    }
                }
            }
        }
}

const handleItem = (item: any, keyPrefix: string, facetMap: Map<string, KeyMetadata>) => {
    for (let key in item) {
        const fullKey = keyPrefix ? keyPrefix + "." + key : key;

        if (Array.isArray(item[key])) {
            continue;
        }
        if (typeof item[key] === "object") {
            handleItem(item[key], fullKey, facetMap);
            continue;
        }


        if (!facetMap.has(fullKey)) {
            facetMap.set(fullKey, {
                valueCounts: new Map<string, number>()
            });
        }

        addValueCount(item[key], facetMap.get(fullKey).valueCounts);
    }
}

const addValueCount = (value: any, map: Map<string, number>) => {
    const valueStr = "" + value;
    if (!map.has(valueStr)) {
        map.set(valueStr, 0);
    }

    map.set(valueStr, map.get(valueStr) + 1);
};

export interface Facet {
    key: string;

    value: string | boolean;
    type?: "Value" | "Existence";
}

// Represents information about some particular key in the data table
export interface KeyMetadata {
    valueCounts: Map<string, number>;
}