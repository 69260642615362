import React from "react";
import { makeDynamicUploadRequest, makeRequest, Organization } from "../../common/api";
import { TextInput } from "../../common/components/Forms";
import { FlexRow, getFileDownloadUrl, InfoTip, LoadingIndicator, LoadingPage, MarkdownTip, PageTitle, useTitle } from "../../common/features/Utils";
import { UserContext } from "../../common/UserContext";

/** Organization editor */
export const OrganizationEditor = () => {
  useTitle("Organization Management");
  const user = React.useContext(UserContext)?.user;
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [org, setOrg] = React.useState<Organization | null>();

  // Read the org from the global context to initially populate this page
  React.useEffect(() => {
    makeRequest(`/api/organizationForCurrentDomain`, "GET").then((currentOrg) => {
      setOrg(currentOrg);
    });
  }, []);

  const [logoUpload, setLogoUpload] = React.useState<any>(null);
  const [iconUpload, setIconUpload] = React.useState<any>(null);
  const saveChanges = async () => {
    setIsLoading(true);
    setErrorMessage("");
    const data = {
      ...org,
    };

    try {
      if (logoUpload) {
        const fileInfo = await makeDynamicUploadRequest(user?.email, "organizationLogo", logoUpload);
        data.logoFile = fileInfo;
      }
      if (iconUpload) {
        const iconInfo = await makeDynamicUploadRequest(user?.email, "organizationIcon", iconUpload);
        data.iconFile = iconInfo;
      }

      makeRequest(`/api/organizations/${org._id}`, "PUT", data).then(org => {
        setIsLoading(false);
        setOrg(org);
      }, err => {
        setIsLoading(false);
        setErrorMessage(err.message);
      });
    } catch (err) {
      setIsLoading(false);
      setErrorMessage(err.message);
    }
  };

  if (!org) {
    return <LoadingPage />;
  }


  return (
    <div>
      <PageTitle>Your Organization</PageTitle>
      <div>
        <label>Name</label>
        <input disabled={isLoading} type="text" value={org.name} onChange={e => setOrg({ ...org, name: e.target.value })} />
      </div>
      <div>
        <label>
          <MarkdownTip />
          <br />
          Description/details
        </label>
        <textarea disabled={isLoading} rows={6} cols={80} value={org.description} onChange={e => setOrg({ ...org, description: e.target.value })} />
      </div>
      <h4>Access</h4>
      <p>Whitelabel access enabled using a custom domains. Please coordinate with the site administrator prior to modifying routes.</p>
      <div>
        <label>
          URL (slug){" "}
          <InfoTip tip="Optional. Allows you to customize the URL for this entry. Only allows lower case a-z, digits 0-9 and the '-' character. Must be at least 6 characters long." />
        </label>
        https://dreamstack.app/apply/<input disabled={isLoading} type="text" value={org.slug} onChange={e => setOrg({ ...org, slug: e.target.value })} />
      </div>
      <div>
        <label>
          Subdomain{" "}
          <InfoTip tip="Currently requires manual configuration to take effect. Please contact the site administrator if you want to change this field." />
        </label>
        <input disabled={isLoading} type="text" value={org.subdomain} onChange={e => setOrg({ ...org, subdomain: e.target.value })} />
      </div>
      <div>
        <label>
          Full domain{" "}
          <InfoTip tip="Currently requires manual configuration to take effect. Please contact the site administrator if you want to change this field." />
        </label>
        <input disabled={isLoading} type="text" value={org.fullDomain} onChange={e => setOrg({ ...org, fullDomain: e.target.value })} />
      </div>

      <br />
      <h4>Branding</h4>
      <FlexRow>
        <div>
          <label>
            Site Logo
        </label>
          <input type="file" onChange={e => setLogoUpload(e.target.files[0])} />
        </div>
        <div style={{ padding: "0 10px 20px 20px" }}>
          {org.logoFile && <img alt="org logo" src={getFileDownloadUrl(org.logoFile)} style={{ height: "50px" }} />}
        </div>
      </FlexRow>

      <FlexRow>
        <div>
          <label>
            Site Favicon (32x32 png)
        </label>
          <input type="file" onChange={e => setIconUpload(e.target.files[0])} />
        </div>
        <div style={{ padding: "0 10px 20px 20px", }}>
          {org.iconFile && <img alt="org icon" src={getFileDownloadUrl(org.iconFile)} style={{ height: "32px" }} />}
        </div>

      </FlexRow>
      <br />
      <h4>Email configuration</h4>
      <div>
        <label>
          Sendgrid API Key{" "}
          <InfoTip tip="You must provide your own SendGrid API key to send emails from your preferred email address." />
        </label>
        <input type="text" disabled={isLoading} value={org.sendGridApiKey} onChange={e => setOrg({ ...org, sendGridApiKey: e.target.value })} />
      </div>
      <div>
        <label>
          Sendgrid email{" "}
          <InfoTip tip="This is the email you want to send from. Please configure it in SendGrid." />
        </label>
        <input type="text" disabled={isLoading} value={org.sendGridEmailAddress} onChange={e => setOrg({ ...org, sendGridEmailAddress: e.target.value })} />
      </div>
      <br />
      <h4>Analytics configuration</h4>
      <div>
        <TextInput object={org} onChange={setOrg} propName="googleAnalyticsId" label="Google Analytics Id" />
      </div>
      <div>
        <TextInput object={org} onChange={setOrg} propName="facebookPixelId" label="Facebook Pixel Id" />
      </div>

      <h4>Other integrations</h4>
      <div>
      <TextInput object={org} onChange={setOrg} propName="calendlyAccessToken" label="Calendly Personal Access Token" />
      </div>

      {errorMessage && (<div style={{ color: "red" }}>{errorMessage}</div>)}
      <button onClick={saveChanges} disabled={isLoading}>
        {isLoading && <LoadingIndicator />}{" "}
        Save
      </button>
    </div>
  );
}