import React from "react";
import styled from "styled-components";
import { BatchesContext } from "../common/BatchesContext";
import { UserContext } from "../common/UserContext";
import { Application, Batch, Process, makeRequest } from "../common/api";
import { FixedContainer, PageBody } from "../common/components/PageBody";
import { ApplyForm } from "../common/features/ApplyForm";
import { LoginForm } from "../common/features/LoginForm";
import { LoadingPage, Markdown } from "../common/features/Utils";
import { YourApplications } from "../common/features/YourApplications";

const BatchItem = styled.div`
  margin: 20px;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.4);
  display: inline-flex;
  flex-direction: column;
  max-width: calc(100% - 20px);
  overflow: auto;

  border-radius: 5px;
`;

interface BatchCardProps {
  batch: Batch | null;
}
const BatchCard = (props: BatchCardProps) => {
  return (
    <BatchItem>
      <div style={{ padding: "10px" }}>
        <h5 style={{ fontSize: "18px", fontWeight: "bold" }}>
          {props.batch.name}
        </h5>
        <Markdown content={props.batch.description} />
      </div>
    </BatchItem>
  );
};

interface Props {}
export const ApplyPage = (props: Props) => {
  const userContext = React.useContext(UserContext);
  const batches = React.useContext(BatchesContext)?.batches;

  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [application, setApplication] = React.useState<Application | null>(
    null
  );
  const [allApplications, setAllApplications] = React.useState<
    Application[] | null
  >(null);

  React.useEffect(() => {
    if (userContext?.user)
      makeRequest("/api/applications", "GET").then((apps: Application[]) => {
        setAllApplications(apps);
      });
  }, [userContext]);

  // The process that was used to submit the application, if one has been submitted.
  const [submittedProcess, setSubmittedProcess] =
    React.useState<Process | null>(null);

  if (!batches) {
    return <LoadingPage />;
  }

  const activeBatches = batches.filter((b) => b.status === "Open");

  if (activeBatches.length === 0) {
    return (
      <PageBody>
        <FixedContainer>
          Sorry, this school is not currently accepting applications for any of
          our programs.
          <br />
          <br />
          We value all potential students and encourage you to check back soon,
          or follow us on Facebook to find out as soon as we announce our next
          schedule.
        </FixedContainer>
      </PageBody>
    );
  }

  return (
    <PageBody>
      <FixedContainer>
        <div>
          <YourApplications />
          <div>
            {activeBatches?.map((batch: Batch) => (
              <BatchCard batch={batch} key={batch._id} />
            ))}
          </div>
        </div>

        {!isSubmitted && (
          <ApplyForm
            onSubmitted={(app: Application, process: Process) => {
              setApplication(app);
              setSubmittedProcess(process);
              setIsSubmitted(true);
              if (userContext?.user) {
                window.location.href = `/application/${app._id}`;
              }
            }}
          />
        )}
        {isSubmitted &&
          !userContext?.user &&
          submittedProcess?.requestSignup && (
            <>
              <p>
                Thank you, {application?.firstName}. Add a password so you can
                manage and update your application.
              </p>

              <div style={{ width: "300px" }}>
                <LoginForm
                  isSignupOnly={true}
                  onComplete={async (user) => {
                    if (application && user) {
                      application.userId = user._id;
                      await makeRequest(
                        `/api/applications/${application._id}`,
                        "PUT",
                        application
                      );
                      window.location.href = `/application/${application._id}`;
                    }
                  }}
                  email={application?.email}
                />
              </div>
            </>
          )}
        {isSubmitted &&
          !userContext?.user &&
          !submittedProcess?.requestSignup && (
            <div>Thank you for providing your information!</div>
          )}
      </FixedContainer>
    </PageBody>
  );
};
