import { User } from "./api";

const checkRoles = (user: User | null | undefined, orgId: string, roles: ("Owner" | "Admin" | "Viewer")[]) => {
    if (!user || !user.orgRoles) {
        return false;
    }
    if (!orgId) {
      return false;
    }

    const orgRoles = user.orgRoles.filter(e => orgId === e.orgId).map(e => e.role);
    return roles.reduce((ok, role) => ok || orgRoles.includes(role), false);
}

export const isUserOwner = (user: User | null | undefined, orgId: string) => {
   return checkRoles(user, orgId, ["Owner"]);
};
export const isUserAdmin = (user: User | null | undefined, orgId: string) => {
    return checkRoles(user, orgId, ["Owner", "Admin"]);
};
export const isUserViewer = (user: User | null | undefined, orgId: string) => {
    return checkRoles(user, orgId, ["Owner", "Admin", "Viewer"]);
};

/** A 'creator' is a user who has signed up to create processes (as opposed to a user who is just an applicant) */
export const isUserCreator = (user: User | null | undefined) => {
    return user?.orgId || user?.isCreator;
};