import React from "react";
import { Assessment, DynamicCondition, DynamicConditionOperatorOptions, DynamicConditionTargetOptions, DynamicConditionTerm, DynamicContent, DynamicFormConfig, DynamicIntegration, makeRequest, PaymentModel, Process, ProcessStep, STATUS_VALUES } from "../../common/api";
import { arrayRemove, arrayReplace, InfoTip, LoadingIndicator } from "../../common/features/Utils";
import { SimpleDataTable } from "../../common/components/SimpleDataTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { justConfirm } from "../../common/components/Modals";
import DOMPurify from "dompurify";
import marked from "marked";
import styled from "styled-components";
import { Prompt } from 'react-router'
import { BoolInput } from "../../common/components/Forms";
import { Menu, MenuItem } from "@szhsin/react-menu";

const InlineBlock = styled.div`display: inline-block;`;

export const NEW_PROCESS: Process = {
  label: "",
  fields: [],
  applicationForm: {
    route: "/api/applications",
    method: "POST",
    fields: [
      {
        name: "email",
        label: "email",
        dataType: "email",
      }
    ],
    title: "Start an application"
  },
  applicationDetailsSteps: []
};

export const AssessmentsContext = React.createContext<Assessment[] | null>(null);
export const PaymentModelsContext = React.createContext<PaymentModel[] | null>(null);

interface Props {
  process: Process;
  onSaved: (process: Process) => void;
  onClose: () => void;
  isAdding?: boolean;
}
export const ProcessConfigEditor = (props: Props) => {
  const [process, setProcess] = React.useState<Process | null>(props.process);

  const [errorMessage, setErrorMessage] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  const [assessments, setAssessments] = React.useState<Assessment[] | null>(null);
  const [paymentModels, setPaymentModels] = React.useState<PaymentModel[] | null>(null);
  React.useEffect(() => {
    makeRequest("/api/assessments", "GET").then(items => setAssessments(items));
    makeRequest("/api/paymentModels", "GET").then(items => setPaymentModels(items));
  }, []);

  const submitProcess = () => {
    setIsLoading(true);

    const route = props.isAdding ? "/api/processConfigBlobs" : `/api/processConfigBlobs/${process._id}`;
    const method = props.isAdding ? "POST" : "PUT";
    makeRequest(route, method, { json: JSON.stringify(process) }).then(() => {
      setIsLoading(false);
      props.onSaved(process);
    }, (err) => {
      setIsLoading(false);
      setErrorMessage("Failed to save blob: " + err.message);
    });
  }

  const closeAndDiscard = () => {
    props.onClose();
  }

  const hasUnsavedChanges = JSON.stringify(process) !== JSON.stringify(props.process);

  return (<div>
    <AssessmentsContext.Provider value={assessments}>
      <PaymentModelsContext.Provider value={paymentModels}>
        <div style={{ position: "sticky", top: 0, padding: "10px", backgroundColor: "#FFF", borderBottom: "1px solid #DDD", margin: "10px 0" }}>
          {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
          <button type="button" disabled={isLoading || !hasUnsavedChanges}
            onClick={() => submitProcess()}>
            {isLoading && <LoadingIndicator />} Save
          </button>
          <button className="secondary" type="button" disabled={isLoading} onClick={() => closeAndDiscard()}>Close</button>
          <span style={{ marginLeft: "20px", color: "#666", fontStyle: "italic" }}>
            {hasUnsavedChanges ? "You have unsaved changes." : "No changes detected."}
          </span>
        </div>

        {process && (
          <>
            <h3>Application Process</h3>
            <div>
              <label>
                Label{" "}
                <InfoTip tip="Admin use only to keep track of processes. Will not be shown to applicants." />
              </label>
              <input type="text" value={process.label} onChange={e => setProcess({ ...process, label: e.target.value })} />
            </div>
            <div>
              <BoolInput object={process} propName={"requestSignup"}
                onChange={setProcess} label="Request user signup"
                disabled={isLoading} />
            </div>

            <h3>Dashboard Fields</h3>
            <p>Customize the fields that will appear on your dashboard</p>
            <div>
              <SimpleDataTable data={process.fields} enableEdit={true} enableAdd={true}
                keys={["name", "label", "dataType", "isAdminOnly"]}
                onRowChange={(newRow, index) =>
                  setProcess({ ...process, fields: arrayReplace(process.fields, newRow, index) })
                }
                onRowDelete={(index) =>
                  setProcess({ ...process, fields: arrayRemove(process.fields, index) })
                }
              />
            </div>

            <h3>Initial Application Form</h3>
            <FormConfigEditor config={process.applicationForm}
              onConfigChange={config => setProcess({ ...process, applicationForm: config })} />
            <br /><br />

            <h3>Application Additional Forms</h3>
            <ApplicationDetailsEditor steps={process.applicationDetailsSteps}
              onStepsChange={steps => setProcess({ ...process, applicationDetailsSteps: steps })} />
          </>
        )}
        <Prompt
          when={hasUnsavedChanges}
          message='You have unsaved changes, are you sure you want to leave?'
        />
      </PaymentModelsContext.Provider>
    </AssessmentsContext.Provider>
  </div>);
}

interface FormEditorProps {
  config: DynamicFormConfig;
  onConfigChange: (config: DynamicFormConfig) => void;
}
const FormConfigEditor = (props: FormEditorProps) => {

  return (
    <div>
      <div>
        <label>Form Title:</label>
        <input type="text"
          value={props.config.title}
          onChange={e => props.onConfigChange({ ...props.config, title: e.target.value })}
        />
      </div>

      <div><b>Inputs</b></div>
      <div>
        <SimpleDataTable data={props.config.fields} enableEdit={true} enableAdd={true}
          onRowChange={(newRow, index) =>
            props.onConfigChange({ ...props.config, fields: arrayReplace(props.config.fields, newRow, index) })
          }
          onRowDelete={(index) =>
            props.onConfigChange({ ...props.config, fields: arrayRemove(props.config.fields, index) })
          } />
      </div>
    </div>)
}

interface ApplicationDetailsEditorProps {
  steps: ProcessStep[];
  onStepsChange: (steps: ProcessStep[]) => void;
}
const ApplicationDetailsEditor = (props: ApplicationDetailsEditorProps) => {

  const addNewStep = (type: "assessments" | "content" | "form" | "paymentModels") => {
    const newStep: ProcessStep = {
      title: "New step",
      subtitle: ""
    };
    if (type === "assessments") {
      newStep.assessmentIds = [];
    }
    if (type === "content") {
      newStep.content = [];
    }
    if (type === "form") {
      newStep.configuration = {
        route: "/api/applications",
        method: "POST",
        title: "New step",
        fields: [{
          name: "",
          label: "",
          dataType: "text",
          details: ""
        }]
      };
    }
    if (type === "paymentModels") {
      newStep.availablePaymentModels = [];
    }
    props.onStepsChange([...props.steps, newStep])
  };

  return (<div>
    {props.steps.map((step, index) => (
      <div key={"" + index}>
        <div style={{ display: "flex" }}>
          <h4 style={{ flexGrow: 1 }}>Step #{index}</h4>
          <div style={{ padding: "10px" }}>
            <button type="button" className="inline secondary" title="Reorder this step"
              onClick={() => {
                const response = prompt("Select a new step number for this item (the first item is 1)");
                const newIndex = parseInt(response);
                if (typeof newIndex === "number" && !isNaN(newIndex)) {
                  const newSteps = [...props.steps];
                  newSteps.splice(index, 1);
                  newSteps.splice(newIndex, 0, step);
                  props.onStepsChange(newSteps)
                }
              }}>
              <FontAwesomeIcon icon="sort-numeric-up" />
            </button>
            <button type="button" className="inline secondary" title="Remove this step"
              onClick={() => {
                justConfirm(`Are you sure you want to remove Step #${index}? (You'll still need to save changes)`).then(() => {
                  props.onStepsChange(arrayRemove(props.steps, index))
                })
              }}>
              <FontAwesomeIcon icon="trash" />
            </button>
          </div>
        </div>
        <div style={{ marginLeft: "10px", padding: "10px", backgroundColor: "#F4F2F5", borderRadius: "10px" }}>
          <StepEditor step={step}
            onStepChange={newStep => props.onStepsChange(arrayReplace(props.steps, newStep, index))} />
        </div>
      </div>
    ))}

    <div style={{ padding: "20px 10px" }}>
      <button type="button" className="inline secondary" onClick={() => addNewStep("form")}>
        <FontAwesomeIcon icon="plus" /> New Form step
      </button>
      <button type="button" className="inline secondary" onClick={() => addNewStep("content")}>
        <FontAwesomeIcon icon="plus" /> New Content step
      </button>
      <button type="button" className="inline secondary" onClick={() => addNewStep("assessments")}>
        <FontAwesomeIcon icon="plus" /> New Assessments step
      </button>
      <button type="button" className="inline secondary" onClick={() => addNewStep("paymentModels")}>
        <FontAwesomeIcon icon="plus" /> New Payment Model Selection step
      </button>
    </div>
  </div>);
};

interface StepEditorProps {
  step: ProcessStep;
  onStepChange: (step: ProcessStep) => void;
}
const StepEditor = (props: StepEditorProps) => {
  const assessments = React.useContext(AssessmentsContext);
  const paymentModels = React.useContext(PaymentModelsContext);

  const [selectedAssessmentId, setSelectedAssessmentId] = React.useState<string>("");
  const [selectedPaymentModelId, setSelectedPaymentModelId] = React.useState<string>("");
  const addSelectedAssessment = () => {
    if (selectedAssessmentId) {
      props.onStepChange({ ...props.step, assessmentIds: [...props.step.assessmentIds, selectedAssessmentId] })
      setSelectedAssessmentId("");
    }
  };
  const addSelectedPaymentModel = () => {
    if (selectedPaymentModelId) {
      props.onStepChange({ ...props.step, availablePaymentModels: [...props.step.availablePaymentModels, selectedPaymentModelId] })
      setSelectedPaymentModelId("");
    }
  };
  const handleAssessmentSelectChange = (e: any) => setSelectedAssessmentId(e.target.value);
  const onClickRemoveAssessment = (assessmentId: string) => {
    justConfirm(`Are you sure you want to remove assessment ${assessmentId}? (you'll still need to save changes)`).then(
      () => props.onStepChange({ ...props.step, assessmentIds: props.step.assessmentIds.filter(ass => ass !== assessmentId) })
    );
  }
  const onClickRemovePaymentModel = (modelId: string) => {
    props.onStepChange({ ...props.step, availablePaymentModels: props.step.availablePaymentModels.filter(model => model !== modelId) });
  }

  const [isEditingStatus, setIsEditingStatus] = React.useState(false);
  const [isEditingVisibility, setIsEditingVisibility] = React.useState(false);


  return (<div style={{ marginTop: "20px" }}>
    <div style={{ display: "flex" }}>
      <div style={{ width: "50%" }}>
        <div>
          <label>Step Title:</label>
          <input type="text"
            value={props.step.title}
            onChange={e => props.onStepChange({ ...props.step, title: e.target.value })}
          />
        </div>
        <div>
          <label>Step Subtitle:</label>
          <input type="text"
            value={props.step.subtitle}
            onChange={e => props.onStepChange({ ...props.step, subtitle: e.target.value })}
          />
        </div>
      </div>
      <div style={{ width: "50%" }}>
        <div>
          <label>Status calculation:</label>
          <InlineBlock>

            {isEditingStatus ? (
              <>
                <ConditionEditor condition={props.step.completionCondition}
                  onChange={newCondition => props.onStepChange({ ...props.step, completionCondition: newCondition })} />
                <div>
                  <button className="inline secondary" onClick={() => setIsEditingStatus(false)}>
                    <FontAwesomeIcon icon="check" /> Done
                  </button>
                </div>
              </>
            ) : (
              <>
                {props.step.completionCondition ? (<><FontAwesomeIcon icon="code-branch" /> Conditional</>) : (<>Automatic</>)}
                <button type="button" className="inline secondary" onClick={() => setIsEditingStatus(true)}>
                  <FontAwesomeIcon icon="edit" />
                </button>
              </>)}
          </InlineBlock>
        </div>
        <div>
          <label>Visibility:</label>
          <InlineBlock>
            {isEditingVisibility ? (
              <>
                <ConditionEditor condition={props.step.visibilityCondition}
                  onChange={newCondition => props.onStepChange({ ...props.step, visibilityCondition: newCondition })} />
                <div>
                  <button className="inline secondary" onClick={() => setIsEditingVisibility(false)}>
                    <FontAwesomeIcon icon="check" /> Done
                  </button>
                </div>
              </>
            ) :
              <>
                {props.step.visibilityCondition ? (<><FontAwesomeIcon icon="code-branch" /> Conditional</>) : (<>Always visible</>)}
                <button type="button" className="inline secondary" onClick={() => setIsEditingVisibility(true)}>
                  <FontAwesomeIcon icon="edit" />
                </button>
              </>}
          </InlineBlock>
        </div>

      </div>
    </div>
    <br />

    {
      props.step.content && (
        <div>
          <div>This step includes dynamic content (markdown):</div>
          <div>
            {props.step.content.map((content, index) => (
              <div key={"" + index}>
                <h5>Block #{index + 1}</h5>
                <DynamicContentEditor content={content}
                  onChange={newContent => props.onStepChange({ ...props.step, content: arrayReplace(props.step.content, newContent, index) })} />
              </div>
            ))}


            <SimpleDataTable data={props.step.content} enableEdit={true} enableAdd={true}
              onRowChange={(newRow, index) =>
                props.onStepChange({ ...props.step, content: arrayReplace(props.step.content, newRow, index) })
              }
              onRowDelete={(index) =>
                props.onStepChange({ ...props.step, content: arrayRemove(props.step.content, index) })
              } />
          </div>
        </div>
      )
    }

    {
      props.step.configuration && (
        <div>
          <div>This step includes a dynamic form:</div>
          <FormConfigEditor config={props.step.configuration}
            onConfigChange={config => props.onStepChange({ ...props.step, configuration: config })} />
        </div>
      )
    }

    {
      props.step.availablePaymentModels && (<div>
        <div>This step allows selection of the following payment models:</div>
        <div>
          {props.step.availablePaymentModels?.map((modelId, index) => (
            <div key={modelId}>
              {index}. {paymentModels?.find(ass => ass._id === modelId)?.name || "Unknown Payment Model"}
              <button type="button" className="secondary inline" onClick={() => onClickRemovePaymentModel(modelId)}>
                <FontAwesomeIcon icon="trash" />
              </button>
            </div>
          ))}
        </div>

        <div>
          <b>Or add a payment model:</b>
          <select value={selectedPaymentModelId} onChange={(e) => setSelectedPaymentModelId(e.target.value)}>
            <option value={""}></option>
            {paymentModels?.filter(model => !props.step.availablePaymentModels.includes(model._id))
              .map(model => (
                <option value={model._id} key={model._id}>
                  {model.name}
                </option>))}
          </select>
          <button type="button" className="inline secondary" onClick={addSelectedPaymentModel}>Add</button>
        </div>
      </div>)
    }

    {
      props.step.assessmentIds && (
        <div>
          <div>This step requires completion of assessments:</div>
          <div>
            {props.step.assessmentIds?.map((assId, index) => (
              <div key={assId}>
                {index}. {assessments?.find(ass => ass._id === assId).name}
                <button type="button" className="secondary inline" onClick={() => onClickRemoveAssessment(assId)}>
                  <FontAwesomeIcon icon="trash" />
                </button>
              </div>
            ))}
          </div>

          <div>
            <b>Or add new assessments:</b>
            <select value={selectedAssessmentId} onChange={handleAssessmentSelectChange}>
              <option value={""}></option>
              {assessments?.filter(ass => !props.step.assessmentIds.includes(ass._id))
                .map(ass => (
                  <option value={ass._id} key={ass._id}>
                    {ass.name}
                  </option>))}
            </select>
            <button type="button" className="inline secondary" onClick={addSelectedAssessment}>Add</button>
          </div>
        </div>
      )
    }
  </div >);
};

interface DynamicContentEditorProps {
  content: DynamicContent;
  onChange: (content: DynamicContent) => void;
}
export const DynamicContentEditor = (props: DynamicContentEditorProps) => {
  return (
    <div style={{ marginLeft: "20px", padding: "20px", backgroundColor: "#E1E5F2", borderRadius: "10px" }}>
      <div style={{ width: "100%", display: "flex" }}>
        <div style={{ maxHeight: "200px", width: "50%", overflow: "auto" }}>
          <textarea style={{ height: "90%", width: "95%" }} value={props.content.markdown}
            onChange={e => props.onChange({ ...props.content, markdown: e.target.value })} />
        </div>
        <div style={{ maxHeight: "200px", width: "50%", overflow: "auto" }}>
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(marked(props.content.markdown || "")) }}></div>
        </div>
      </div>

      <ConditionEditor condition={props.content.condition}
        onChange={newCondition => props.onChange({ ...props.content, condition: newCondition })} />

      <IntegrationsEditor integrations={props.content.integrations} 
        onChange={newIntegrations => props.onChange({ ...props.content, integrations: newIntegrations })} />
    </div>);

}

interface ConditionEditorProps {
  condition: DynamicCondition | null;

  // If no condition is returned, then nullify the condition on the parent
  onChange: (condition: DynamicCondition | null) => void;
}
export const ConditionEditor = (props: ConditionEditorProps) => {
  const newTerm: DynamicConditionTerm = {
    target: "STATUS",
    operator: "Greater than",
    comparisonValue: "CANCELLED"
  };

  const addNewTerm = () => {
    props.onChange({ ...props.condition, terms: [...props.condition.terms, newTerm] });
  }

  const [editIndex, setEditIndex] = React.useState(-1);

  if (!props.condition) {
    return <button type="button" className="inline secondary"
      onClick={() => props.onChange({
        logicalOperator: "AND",
        terms: [newTerm]
      })}>
      <FontAwesomeIcon icon="plus" /> Add conditional logic
    </button>
  }

  return (
    <div style={{ margin: "10px 0" }}>
      <h6>Conditions</h6>
      <label>Select grouping</label>
      <select value={props.condition.logicalOperator}
        onChange={e => props.onChange({ ...props.condition, logicalOperator: e.target.value as any })}>
        <option value="OR">OR</option>
        <option value="AND">AND</option>
      </select >
      <span style={{ marginLeft: "20px" }}>
        ({props.condition.logicalOperator === "AND" ? "All of the listed conditions must match." : "Any one or more of the listed conditions must match"})
      </span>

      <div>
        {props.condition.terms.map((term, index) =>
        (<div key={"" + index}>
          <>
            {index === editIndex ? (
              <ConditionTermEditor term={term} onClose={() => setEditIndex(-1)}
                onChange={newTerm => props.onChange({ ...props.condition, terms: arrayReplace(props.condition.terms, newTerm, index) })} />
            ) : (
              <>
                <span style={{ display: "inline-block", width: "250px" }}>{stringifyTerm(term)}</span>
                <button type="button" className="inline secondary" onClick={() => setEditIndex(index)}>
                  <FontAwesomeIcon icon="edit" />
                </button>
                <button type="button" className="inline secondary" onClick={() => {
                  justConfirm(`Are you sure you want to remove term "${stringifyTerm(term)}"?`).then(() => {
                    props.onChange({ ...props.condition, terms: arrayRemove(props.condition.terms, index) });
                    setEditIndex(-1);
                  });
                }}>
                  <FontAwesomeIcon icon="trash" />
                </button>
              </>
            )}
          </>
        </div>
        ))}


        <button type="button" className="inline secondary" onClick={addNewTerm}>
          <FontAwesomeIcon icon="plus" /> New term
        </button>
        <button type="button" className="inline secondary" onClick={() => {
          justConfirm(`Are you sure you want to remove all conditions from this dynamic element?`).then(() => {
            props.onChange(null);
          });
        }}>
          <FontAwesomeIcon icon="trash" /> Clear condition
        </button>
      </div>
    </div>
  );
}

// Allows the admin to add and configure special integrations that add additional logic
// to a Block
interface IntegrationsEditorProps {
  integrations: DynamicIntegration[] | null;
  onChange: (integrations: DynamicIntegration[] | null) => void;
}
export const IntegrationsEditor = (props: IntegrationsEditorProps) => {

  if (!props.integrations) {
    return <Menu menuButton={
      <button type="button" className="inline secondary">
      <FontAwesomeIcon icon="plus" /> Add integration
      </button>
    }>
      <MenuItem onClick={() => props.onChange([
        {
          type: "Calendly",
          url: "",
        }
      ])}>Calendly</MenuItem>
    </Menu>      
  }

  // Only reaches this if the integration is added
  return <div style={{margin: "10px 0"}}>
    <h6>Integrations</h6>
    {props.integrations.map((integration, index) =>
      <div key={"" + index}>
        {integration.type}
        <br />
        <label>Event url</label>
        <input type="text" value={integration.url} onChange={e => 
          props.onChange(arrayReplace(props.integrations, { ...integration, url: e.target.value }, index))
        }/>
      </div>)}
  </div>;
};

interface TermEditorProps {
  term: DynamicConditionTerm;
  onChange: (term: DynamicConditionTerm) => void;
  onClose: () => void;
}
export const ConditionTermEditor = (props: TermEditorProps) => {


  return (<div style={{ padding: "10px", backgroundColor: "#ECE4E0", borderRadius: "10px" }}>
    <div>
      <label htmlFor="termTarget">Target</label>
      <select id="termTarget" value={props.term.target} onChange={e => props.onChange({ ...props.term, target: e.target.value as any })}>
        {DynamicConditionTargetOptions.map(option => <option key={option} value={option}>{option}</option>)}
      </select>
    </div>
    {props.term.target === "FIELD" && <div>
      <label htmlFor="fieldTarget">Field name</label>
      <input id="fieldTarget" type="text" value={props.term.targetDetails || ""} onChange={e => props.onChange({ ...props.term, targetDetails: e.target.value })} />
    </div>}
    <div>
      <label htmlFor="termOperator">Operator</label>
      <select id="termOperator" value={props.term.operator} onChange={e => props.onChange({ ...props.term, operator: e.target.value as any })}>
        {DynamicConditionOperatorOptions.map(option => <option key={option} value={option}>{option}</option>)}
      </select>
    </div>
    {
      props.term.operator !== "Exists" && <div>
        <label htmlFor="termComparisonValue">Comparison value</label>
        {props.term.target === "STATUS" ? (
          <div>
            <select id="termComparisonValue" value={props.term.comparisonValue} onChange={e => props.onChange({ ...props.term, comparisonValue: e.target.value })}>
              {STATUS_VALUES.map(val => <option key={val} value={val}>{val}</option>)}
            </select>
          </div>
        ) : (
          <input type="text" value={props.term.comparisonValue} onChange={e => props.onChange({ ...props.term, comparisonValue: e.target.value })} />
        )}
      </div>
    }
    <div style={{ padding: "10px" }}>
      <button type="button" className="inline secondary" onClick={() => props.onClose()}>
        <FontAwesomeIcon icon="check" /> Done
      </button>
    </div>
  </div>)
}

export const stringifyTerm = (term: DynamicConditionTerm) => {
  let str = "";
  str = term.target;
  if (term.targetDetails) {
    str += `[${term.targetDetails}]`;
  }
  str += " " + term.operator;

  if (term.comparisonValue !== null && term.comparisonValue !== undefined) {
    str += " " + term.comparisonValue;
  }

  return str;
}