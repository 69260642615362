import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Application, Assessment, makeRequest } from "../common/api";
import { FixedContainer, PageBody } from "../common/components/PageBody";
import { TakeAssessment } from "../common/features/TakeAssessment";
import { LoadingPage } from "../common/features/Utils";

interface Props { }
export const AssessmentPage = (props: Props) => {
  const { applicationId, assessmentId } = useParams<any>();

  const [errorMessage, setErrorMessage] = React.useState("");
  const [assessment, setAssessment] = React.useState<Assessment | null>(null);
  const [application, setApplication] = React.useState<Application | null>(null);
  React.useEffect(() => {
    makeRequest(`/api/assessments/${assessmentId}`, "GET").then(ass => setAssessment(ass), e => {
      setErrorMessage("Failed to fetch assessment");
    });
    makeRequest(`/api/applications/${applicationId}`, "GET").then(app => setApplication(app), e => {
      setErrorMessage("Failed to fetch application. Are you logged in?");
    });
  }, [applicationId, assessmentId]);

  const history = useHistory();

  if (errorMessage) {
    return (<PageBody>
      {errorMessage}
    </PageBody>);
  }

  if (!assessment || !application) {
    return <LoadingPage />
  }

  return (<PageBody>
    <FixedContainer>
      <TakeAssessment assessment={assessment} application={application}
        onClose={() => {
          const newUrl = `/application/${application._id}`;
          history.push(newUrl);
        }} />
      </FixedContainer>
  </PageBody>);
}