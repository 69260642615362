import DOMPurify from "dompurify";
import marked from "marked";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FixedContainer, PageBody } from "../common/components/PageBody";
import { LoadingIndicator } from "../common/features/Utils";
import { OrgContext } from "../common/OrgContext";
import { UserContext } from "../common/UserContext";

const StepsList = styled.ol`
  margin-top: 10px;

  & li {
    margin: 8px 0;
  }
`;

export const HomePage = () => {
  const user = React.useContext(UserContext)?.user;
  const org = React.useContext(OrgContext);

  return (
    <PageBody>
      <FixedContainer>
        {!org && (
          <div style={{ color: "#BBB", textAlign: "center", padding: "100px" }}>
            <LoadingIndicator size="4x" />
            <br />
            Loading...
          </div>
        )}
        {org && org !== "NONE" && (
          <div>
            <div style={{ padding: "20px" }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(marked(org.description || "")),
                }}
              ></div>
            </div>
            <div style={{ padding: "20px", textAlign: "center" }}>
              <Link to="/enroll">
                <button>Start your enrollment now!</button>
              </Link>
            </div>
          </div>
        )}
      </FixedContainer>
    </PageBody>
  );
};
