import React from "react";
import { Assessment, Batch, makeRequest } from "../../common/api";
import { isUserAdmin, isUserCreator, isUserOwner } from "../../common/auth";
import { PageBody } from "../../common/components/PageBody";
import { SideBar, SideBarPageBody } from "../../common/components/SideBar";
import { LoadingPage } from "../../common/features/Utils";
import { OrgContext } from "../../common/OrgContext";
import { UserContext } from "../../common/UserContext";
import { Assessments } from "./Assessments";
import { Batches } from "./Batches";
import { Emails } from "./Emails";
import { OrganizationEditor } from "./OrganizationEditor";
import { PaymentModels } from "./PaymentModels";
import { Processes } from "./Processes";
import { Users } from "./Users";
import { Webhooks } from "./Webhooks";

type TabType =
  | "organization"
  | "assessments"
  | "batches"
  | "emails"
  | "forms & processes"
  | "users"
  | "payment models"
  | "webhooks";

interface Props {}
export const AdminConfigPage = (props: Props) => {
  const userContext = React.useContext(UserContext);
  const org = React.useContext(OrgContext);
  const orgId = org && org !== "NONE" && org._id;
  const user = userContext?.user;

  const [batches, setBatches] = React.useState<Batch[] | null>(null);
  const [assessments, setAssessments] = React.useState<Assessment[] | null>(
    null
  );

  const fetchBatches = () =>
    makeRequest("/api/batches?filter=mine", "GET").then((data) => {
      setBatches(data);
    });
  const fetchAssessments = () =>
    makeRequest("/api/assessments", "GET").then((data) => {
      setAssessments(data);
    });

  React.useEffect(() => {
    fetchBatches();
    fetchAssessments();
  }, []);

  const [tab, setTab] = React.useState<TabType>("organization");
  const tabs = ["organization", "batches", "assessments", "emails", "forms & processes"];
  if (isUserAdmin(user, orgId)) {
    tabs.push("payment models");
    tabs.push("webhooks");
  }
  if (isUserOwner(user, orgId)) {
    tabs.push("users");
  }

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tab: any = params.get("tab");
    if (tab && tabs.indexOf(tab) >= 0) {
      setTab(tab);
    }
  }, [tabs]);

  if (!batches || !assessments) {
    return <LoadingPage />;
  }

  return (
    <SideBarPageBody>
      <SideBar
        title="Configuration"
        tabs={tabs}
        saveToQueryString={true}
        onClick={(key: any) => {
          setTab(key);
        }}
      />
      <PageBody fullHeight={true} style={{ textAlign: "left"}}>
        {isUserCreator(user) ? (
          <div>
            {tab === "organization" && <OrganizationEditor />}
            {tab === "batches" && batches && assessments && (
              <Batches
                batches={batches}
                assessments={assessments}
                onChange={fetchBatches}
              />
            )}
            {tab === "assessments" && assessments && (
              <Assessments
                assessments={assessments}
                onChange={fetchAssessments}
              />
            )}
            {tab === "emails" && <Emails />}
            {tab === "forms & processes" && <Processes />}
            {tab === "users" && <Users />}
            {tab === "webhooks" && <Webhooks />}
            {tab === "payment models" && <PaymentModels />}
          </div>
        ) : (
          <div>
            Please log in to a valid creator account to access this page, or
            join an organization.
          </div>
        )}
      </PageBody>
    </SideBarPageBody>
  );
};
