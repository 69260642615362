// Provides a simple way to render a table of data with an option for editing values.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { DataTableColumnDef, SimpleStyledTable } from "./DataTable";
import { justConfirm } from "./Modals";



export const listAllKeys = (data?: any[]): string[] => {
  return Array.from(data?.reduce((acc: Set<string>, cur: any) => {
    Object.keys(cur).forEach(key => acc.add(key));
    return acc;
  }, new Set()) || []);
};

interface Props {
  data: any[] | null | undefined;
  columns?: DataTableColumnDef[];

  // If provided, will be used as the list of columns, otherwise automatically computed
  keys?: string[];

  /** If enabled, you must also provide onRowChange  */
  enableEdit?: boolean;
  enableAdd?: boolean;

  onRowChange?: (updatedRow: any, index: number) => void;

  /** If provided, there will be a delete icon */
  onRowDelete?: (index: number) => void;
}
/**
 * Returns a non-customized simple data view of the entire array of objects
 */
export const SimpleDataTable = (props: Props) => {
  const keys = props.keys || props.columns?.map(c => c.key) || listAllKeys(props.data);

  const [editIndex, setEditIndex] = React.useState(-1);
  const [editData, setEditData] = React.useState<any>({});

  const data = props.data?.filter(row => !!row) || [];

  // This shouldn't be necessary, but some consumers seem to be passing
  // null rows and I want to make sure not to break any end users.
  const normalizeNullishRows = () => {
    let changed = false;
    props.data.forEach((d, i) => {
      if (!d) {
        props.onRowDelete(i);
        changed = true;
      }
    });
    return changed;
  }

  const onAddNewRow = () => {
    if (normalizeNullishRows()) {
      return;
    }

    if (editData && editIndex >= 0) {
      props.onRowChange?.(editData, editIndex);
    }

    const newData: any = {};
    keys.forEach(k => { newData[k] = ""; });

    props.onRowChange?.(newData, data?.length || 0);
    setEditIndex(data?.length || 0);
    setEditData(newData);
  };

  const onDeleteRow = (index: number) => {
    if (normalizeNullishRows()) {
      return;
    }
    const ok = justConfirm("Are you sure you want to remove this row?");
    if (ok) {
      props.onRowDelete?.(index);
    }
  }

  return (
    <>
      <SimpleStyledTable>
        <thead>
          <tr>
            {keys.map(k => <th key={k}>{k}</th>)}
          </tr>
        </thead>
        <tbody>
          {
            data && data.map((row, index) => index === editIndex ? (
              <tr key={"" + index}>
                {keys.map(k => <td key={k}>
                  {(typeof row[k] === "string" || !row[k]) ? (
                    <input type="text" value={editData[k]} onChange={(e) => {
                      const newData = { ...editData };
                      newData[k] = e.target.value
                      setEditData(newData);
                    }} />) : (
                    String(row[k])
                  )}
                </td>)}
                {props.enableEdit && (<td>
                  <button className="inline secondary"
                    title="Confirm changes"
                    onClick={() => {
                      setEditIndex(-1);
                      props.onRowChange?.(editData, index);
                      setEditData({});
                    }}>
                    <FontAwesomeIcon icon="check" />
                  </button>
                  <button className="inline secondary"
                    title="Cancel changes"
                    onClick={() => {
                      setEditIndex(-1);
                      setEditData({});
                    }}
                  ><FontAwesomeIcon icon="times" />
                  </button>
                </td>)}
              </tr>
            ) : (
              <tr key={"" + index}>
                {keys.map(k => <td key={k}>{String(row[k])}</td>)}
                {props.enableEdit && (<td>
                  <button className="inline secondary" title="Start editing"
                    onClick={() => {
                      if (editData && editIndex >= 0) {
                        props.onRowChange?.(editData, editIndex);
                      }
                      setEditData({ ...row });
                      setEditIndex(index);
                    }}>
                    <FontAwesomeIcon icon="edit" />
                  </button>
                  {props.onRowDelete && <button className="inline secondary"
                    title="Delete row"
                    onClick={() => onDeleteRow(index)}>
                    <FontAwesomeIcon icon="trash" />
                  </button>
                  }
                </td>)}
              </tr>
            ))
          }
        </tbody>
      </SimpleStyledTable>
      {props.enableAdd && props.onRowChange && <div>
        <button className="inline secondary" onClick={onAddNewRow}>
          <FontAwesomeIcon icon="plus" /> Add row
            </button>
      </div>}
    </>
  );
};