import React from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { Application, makeRequest, Batch, Organization } from "../common/api";
import { PageBody } from "../common/components/PageBody";
import marked from 'marked';
import { ApplyForm } from "../common/features/ApplyForm";
import { LoginForm } from "../common/features/LoginForm";
import { LoadingPage, Markdown } from "../common/features/Utils";
import { YourApplications } from "../common/features/YourApplications";
import { UserContext } from "../common/UserContext";
import DOMPurify from "dompurify";


const BatchItem = styled.div`
  margin: 20px;
  padding: 10px;
  width: 390px;
  min-height: 240px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  display: inline-flex;
  flex-direction: column;
  max-width: calc(100% - 20px);
  overflow: auto;
`;

interface Props { }
export const ApplyOrgPage = (props: Props) => {
  const userContext = React.useContext(UserContext);
  const { orgId } = useParams<any>();
  const [org, setOrg] = React.useState<Organization | null>(null);
  const [batches, setBatches] = React.useState<Batch[] | null>(null);
  const [errorMessage, setErrorMessage] = React.useState("");
  React.useEffect(() => {
    if (orgId) {
      makeRequest(`/api/organizations/${orgId}`, "GET").then(newOrg => {
        setOrg(newOrg);
        document.title = "Apply to join " + newOrg.name;
      }, err => setErrorMessage(err.message));
      makeRequest(`/api/batches?organizationId=${orgId}`, "GET").then(batches => setBatches(batches),
        err => setErrorMessage(err.message))
    };
  }, [orgId])

  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [application, setApplication] = React.useState<Application | null>(null);

  if (!batches || !org) {
    return <LoadingPage />
  }

  const activeBatches = batches.filter(b => b.status === "Open");
  return (
    <PageBody>
      <div className="homeBody">
        <div>
          <YourApplications />
          <h3>{org.name}</h3>
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(marked(org.description || "")) }}></div>

          <h3>Available programs</h3>
          <div>
            {activeBatches.length === 0 && <div>
              Sorry, this organization is not currently accepting applications for any programs.
            </div>}
            {activeBatches?.map((batch: Batch) => (
              <BatchItem key={batch._id}>
                <h5 style={{ fontSize: "18px" }}>{batch.name}</h5>
                <Markdown content={batch.description} />
              </BatchItem>))}
          </div>
        </div>

        {errorMessage && (<div style={{ color: "red" }}>{errorMessage}</div>)}

        {!isSubmitted && <ApplyForm onSubmitted={(app: Application) => {
          setApplication(app);
          setIsSubmitted(true);
          if (userContext?.user) {
            window.location.href = `/application/${app._id}`;
          }
        }} />}
        {
          /* TODO: Attach the application to the user once created. */
          isSubmitted && !userContext?.user &&
          (<>
            <p>
              Thank you, {application?.firstName}. Add a password so you can
            manage and update your application.
          </p>

            <div style={{ width: "300px" }}>
              <LoginForm
                isSignupOnly={true}
                onComplete={async (user) => {
                  if (application && user) {
                    application.userId = user._id;
                    await makeRequest(`/api/applications/${application._id}`, "PUT", application);
                    window.location.href = `/application/${application._id}`
                  }
                }}
                email={application?.email}
              />
            </div>
          </>)
        }
      </div>
    </PageBody>
  );
}