import React from "react";
import { Link } from "react-router-dom";
import { makeRequest } from "../../common/api";
import { PageBody } from "../../common/components/PageBody";
import { LoadingIndicator } from "../../common/features/Utils";
import { UserContext } from "../../common/UserContext";

export const PasswordResetPage = () => {
    const user = React.useContext(UserContext)?.user

    React.useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const queryToken: any = params.get("token");
        if (queryToken) {
            setToken(queryToken);
        }
    }, []);

    React.useEffect(() => {
        if (user) {
            setEmail(user.email);
            setErrorMessage("You're already logged in as " + user.email + ". Are you sure you want to reset your password?");
        }
    }, [user]);

    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [token, setToken] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);
    const [isDone, setIsDone] = React.useState(false);
    const [isReset, setIsReset] = React.useState(false);


    const sendResetLink = () => {
        setIsLoading(true);
        makeRequest("/api/sendResetLink", "POST", { email }).then(() => {
            setIsDone(true);
            setIsLoading(false);
        }, err => {
            setErrorMessage(err.message);
            setIsLoading(false);
        })
    };
    const doActualReset = () => {
        setIsLoading(true);
        makeRequest("/api/resetPassword", "POST", { email, password, token }).then(() => {
            setIsReset(true);
            setIsLoading(false);
        }, err => {
            setErrorMessage(err.message);
            setIsLoading(false);
        });
    };

    if (isDone) {
        return <PageBody>
            <h3>Email sent!</h3>
            Email sent to <b>{email}</b> if that is a valid user email.
            Please check your inbox for your password reset link. If you don't receive a link,
            please check that you entered the email correctly, or try creating a new account
            with that email address.
        </PageBody>;
    }

    if (isReset) {
        return <PageBody>
            <h3>Password reset complete!</h3>
            Your password has been successfully reset. Please go <Link to="/login">login</Link> now.
        </PageBody>;
    }

    return <PageBody>
        <h3>Reset your password</h3>
        <div>
            <label htmlFor="emailInput">{token ? ("Confirm email") : ("Email address")}</label>
            <input id="emailInput" type="text" disabled={isLoading} value={email} onChange={e => setEmail(e.target.value)} />
        </div>
        <br />
        {errorMessage && (<div style={{ color: "red" }}>{errorMessage}</div>)}
        {token ? (
            <>
                <div>
                    <label htmlFor="newPasswordInput">New password</label>
                    <input id="newPasswordInput" type="password" disabled={isLoading} value={password} onChange={e => setPassword(e.target.value)} />
                </div>
                <button disabled={isLoading} onClick={doActualReset}>
                    {isLoading && <LoadingIndicator />}
                    Reset
                </button>
            </>
        ) : (
                <button disabled={isLoading} onClick={sendResetLink}>
                    {isLoading && <LoadingIndicator />}
                    Send reset link
                </button>
            )}


    </PageBody>;
};