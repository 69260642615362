import React from "react";
import styled from "styled-components";
import { makeRequest, User } from "../api";
import { LoadingIndicator } from "./Utils";

const Body = styled.div`
  text-align: center;

  & form {
    margin-bottom: 20px;
    div {
      margin-bottom: 20px;
    }
  }

  & label {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 0;
  }
  & input[type="text"],
  & input[type="password"] {
    height: 40px;
    padding: 5px 10px;
    margin: 10px;
    border-radius: 8px;
    border: 2px solid rgba(0, 0, 0, 0.2);
  }
  & input:focus {
    border: 2px solid rgba(0, 0, 0, 0.9);
  }
`;

interface Props {
  isSignupOnly?: boolean;
  onComplete?: (user: User | null) => void;
  email?: string;

  // If provided, will force the form to start in signup mode instead.
  startOnSignup?: boolean;
}

export const LoginForm = (props: Props) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [email, setEmail] = React.useState(props.email || "");
  const [password, setPassword] = React.useState("");
  const [isSignUp, setIsSignUp] = React.useState(
    props.isSignupOnly || props.startOnSignup
  );
  const [errorMessage, setErrorMessage] = React.useState("");
  const [token, setToken] = React.useState("");
  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const queryToken: any = params.get("token");
    if (queryToken) {
      setToken(queryToken);
    }
  }, []);

  const onAction = async () => {
    setIsSubmitting(true);
    const formAction = isSignUp ? "/api/signup" : "/api/login";
    try {
      const response = await makeRequest(formAction, "POST", {
        email,
        password,
        isCreator: false,
        token,
      });
      props.onComplete?.(response);
      setTimeout(() => {
        // Delay the submitting end because a navigation should now be occurring
        setIsSubmitting(false);
      }, 3000);
    } catch (ex) {
      setErrorMessage(
        "Failed to log in. Check your email and password or contact us for support."
      );
      setIsSubmitting(false);
    }
  };

  const formLabel = isSignUp ? "Sign Up" : "Login";
  return (
    <Body>
      <div>
        <h3>{formLabel}</h3>
        <div>
          <label>Email</label>
          <input
            type="text"
            name="email"
            required
            value={email}
            disabled={!!props.email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <label>Password</label>
          <input
            type="password"
            name="password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                onAction();
              }
            }}
          />
        </div>
        {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
        <button
          style={{ minWidth: "120px" }}
          disabled={isSubmitting}
          className="primary"
          type="button"
          onClick={onAction}
        >
          {isSubmitting && <LoadingIndicator />} {formLabel}
        </button>
        {isSignUp && (
          <div style={{ margin: "auto", marginTop: "20px" }}>
            Sign up to enroll and track your enrollment
          </div>
        )}
      </div>
      {!props.isSignupOnly && (
        <div style={{ marginTop: "20px" }}>
          {isSignUp ? "Already have an account?" : "Don't have an account yet?"}{" "}
          <button
            className="secondary inline"
            onClick={() => setIsSignUp(!isSignUp)}
          >
            {isSignUp ? "Log in" : "Create your account"}
          </button>
          <br />
        </div>
      )}
      {!isSignUp && (
        <div>
          <br />
          <a href="/passwordReset">Forgot your password?</a>
        </div>
      )}
    </Body>
  );
};
