import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  Assessment,
  AssessmentType,
  FileInfo,
  makeRequest,
  makeUploadRequest,
  Question,
} from "../../common/api";
import { Expandable } from "../../common/components/Expandable";
import { justConfirm } from "../../common/components/Modals";
import { AttachmentLink, MarkdownTip } from "../../common/features/Utils";

interface Props {
  assessment?: Assessment;
  onChange: () => void;
  onClose: () => void;
}
/**
 * Form to add or edit an assessment. If you don't provide an existing assessment,
 * the form will render in "Create" mode. If you do provide on, it will be "Edit" mode.
 */
export const EditAssessmentForm = (props: Props) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [name, setName] = React.useState(props.assessment?.name || "");
  const [type, setType] = React.useState<AssessmentType>(
    props.assessment?.type || "quiz"
  );
  const [description, setDescription] = React.useState(
    props.assessment?.description || ""
  );
  const [instructions, setInstructions] = React.useState(
    props.assessment?.instructions || ""
  );
  const [timeLimitMinutes, setTimeLimitMinutes] = React.useState(
    props.assessment?.timeLimitMinutes || 60
  );
  const [attachment, setAttachment] = React.useState(
    props.assessment?.attachment
  );
  const [attachmentUpload, setAttachmentUpload] = React.useState<any>(null);
  const [questions, setQuestions] = React.useState<Question[]>(
    props.assessment?.questions || []
  );
  const [externalAssessmentUrl, setExternalAssessmentUrl] = React.useState(
    props.assessment?.externalAssessmentUrl || ""
  );
  const [messagingSecretToken, setMessagingSecretToken] = React.useState(
    props.assessment?.messagingSecretToken || ""
  );
  const [errorMessage, setErrorMessage] = React.useState("");

  const onFileInputChange = (e: any) => {
    setAttachmentUpload(e.target.files[0]);
  };
  const onQuestionImageInputChange = async (
    e: any,
    question: Question,
    index: number
  ) => {
    const file = e.target.files[0];
    if (props.assessment) {
      const fileInfo: FileInfo = await makeUploadRequest(
        "image",
        "",
        "image",
        file,
        props.assessment.organizationId
      );
      question.imageUrl = `/api/download/${fileInfo.fileId}`;
      setQuestions([...questions]);
    } else {
      alert("The Create this assessment first before uploading an image");
    }
  };

  const saveChanges = async () => {
    setIsSubmitting(true);
    let updatedAssessment;
    // External assessments do not have a time limit in this application portal.
    // A time limit may be enforced by the external assessment service.
    const adjustedTimeLimitMinutes =
      type === "external" ? null : timeLimitMinutes;
    try {
      if (props.assessment) {
        updatedAssessment = await makeRequest(
          `/api/assessments/${props.assessment._id}`,
          "PUT",
          {
            name,
            type,
            description,
            instructions,
            timeLimitMinutes: adjustedTimeLimitMinutes,
            attachment,
            questions,
            externalAssessmentUrl,
            messagingSecretToken,
          }
        );
      } else {
        updatedAssessment = await makeRequest("/api/assessments", "POST", {
          name,
          type,
          description,
          instructions,
          timeLimitMinutes: adjustedTimeLimitMinutes,
          questions,
          externalAssessmentUrl,
          messagingSecretToken,
        });
      }

      if (attachmentUpload) {
        await makeUploadRequest(
          "assessment",
          updatedAssessment._id,
          "attachment",
          attachmentUpload,
          updatedAssessment.organizationId
        );
      }

      setIsSubmitting(false);
      props.onChange();
    } catch (ex) {
      setErrorMessage(ex.message);
    }
  };

  const onClickRemoveAttachment = () => {
    justConfirm(
      "Are you sure you want to remove the attachment? (You'll still need to save changes)"
    ).then(() => {
      setAttachment(null);
    });
  };

  return (
    <div>
      <h4>
        {props.assessment ? "Edit Assessment" : "New Assessment"} - {type}
      </h4>
      {type === "project" && (
        <p>
          A "project" assessment provides the student with some instructions and
          requests that they upload a file to complete the assessment.
        </p>
      )}
      {type === "quiz" && (
        <p>
          A "quiz" assessment is a multiple-choice test that the student takes
          directly within their application. You can see their score after
          completion.
        </p>
      )}
      {type === "external" && (
        <p>
          An "external" assessment is any assessment which the student takes
          outside of your application website. The external assessment provider
          should send a notification back when the assessment is complete.
        </p>
      )}
      <div>
        <label>Assessment Title</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div>
        <label>Assessment Type</label>
        <select
          value={type}
          onChange={(e) => setType(e.target.value as AssessmentType)}
        >
          <option value={"quiz"}>Quiz</option>
          <option value={"project"}>Project</option>
          <option value={"external"}>External</option>
        </select>
      </div>
      <div>
        <label>
          <MarkdownTip />
          <br />
          Assessment details (always visible)
        </label>
        <textarea
          rows={6}
          cols={80}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      {type === "project" && (
        <div>
          <label>
            <MarkdownTip />
            <br />
            Instructions (shown after starting)
          </label>
          <textarea
            rows={6}
            cols={80}
            value={instructions}
            onChange={(e) => setInstructions(e.target.value)}
          />
        </div>
      )}
      {type !== "external" && (
        <div>
          <label>Time limit (minutes)</label>
          <input
            type="number"
            value={timeLimitMinutes}
            onChange={(e) => setTimeLimitMinutes(parseInt(e.target.value))}
          />
        </div>
      )}
      {type === "project" && (
        <div>
          <label>Attachment</label>
          <div style={{ display: "inline-block" }}>
            <input type="file" onChange={onFileInputChange} />
            {attachment && (
              <span>
                <FontAwesomeIcon
                  icon="check-square"
                  style={{ color: "#4B4" }}
                />
                <AttachmentLink file={attachment} />
                <button
                  className="secondary inline"
                  onClick={onClickRemoveAttachment}
                >
                  Remove
                </button>
              </span>
            )}
          </div>
        </div>
      )}
      {type === "external" && (
        <>
          <label>Assessment URL</label>
          <input
            style={{width: "663px"}}
            type="text"
            name="externalUrl"
            value={externalAssessmentUrl}
            onChange={(e) => setExternalAssessmentUrl(e.target.value)}
          />

          <br /><br />
          <p>
            The external assessment provider should provide a Secret Token used
            to confirm the authenticity of the webhook notification. Copy the
            value from your external provider webhook and paste here.
          </p>
          <label>Secret Token</label>
          <input
            type="text"
            name="secretToken"
            value={messagingSecretToken}
            onChange={(e) => setMessagingSecretToken(e.target.value)}
          />
          {props.assessment?._id && (
            <>
              <Expandable title="Webhook Notification Details" style={{margin: "20px", maxWidth: "800px"}}>
                Configure the external assessment provider to send a
                notification to this URL when the assessment is complete.
                <br /><br />
                We currently only support inbound messages from
                codepractice.dev, whose message format should be as follows.
                <br /><br />
                <h5>Callback Webhook URL</h5>
                <code style={{display: "inline-block", userSelect: "all", wordBreak: "break-all"}}>
                  https://apply.upliftcodecamp.com/api/inbound-webhook?organizationId=
                  {props.assessment.organizationId}&amp;assessmentId=
                  {props.assessment._id}
                </code>
                <br />
                
                <br />
                <h5>Expected Message Format</h5>
                <code
                  style={{ display: "inline-block", whiteSpace: "pre-wrap" }}
                >
                  {`{
  "source": "https://www.codepractice.dev",
  "secretToken": "<YOUR_SECRET_TOKEN>",
  "event": "assignment.completed",
  "userEmail": "testuser@codepractice.dev",
  "detailsUrl": "https://www.codepractice.dev/assignments/12345",
}`}
                </code>
                <p>
                  Where the entirety of that message is itself a JSON 
                  string under the key of a "content" object on the message.
                </p>
              </Expandable>
            </>
          )}
        </>
      )}

      {type === "quiz" && (
        <div>
          <label>Add Questions</label>
          <div style={{ display: "inline-block" }}>
            {questions?.map((q, i) => (
              <div key={`option${i}`} style={{ margin: "20px 0 10px 0" }}>
                <h4>Question #{i + 1}</h4>
                <input
                  type="text"
                  value={q.prompt}
                  placeholder={"Question prompt"}
                  style={{ width: "500px" }}
                  onChange={(e) => {
                    q.prompt = e.target.value;
                    setQuestions([...questions]);
                  }}
                />
                <div>
                  Image (optional):{" "}
                  <input
                    type="file"
                    onChange={(e) => onQuestionImageInputChange(e, q, i)}
                  />
                  {q.imageUrl && (
                    <div>
                      Preview:{" "}
                      <img
                        src={q.imageUrl}
                        alt="User uploaded."
                        style={{ width: "80px" }}
                      />
                    </div>
                  )}
                </div>
                <div>
                  Details (markdown enabled):
                  <textarea
                    value={q.details}
                    onChange={(e) => {
                      q.details = e.target.value;
                      setQuestions([...questions]);
                    }}
                  />
                </div>
                <div>
                  {q.options?.map((o, j) => (
                    <div key={`option${j}`}>
                      <input
                        type="radio"
                        checked={o.isCorrect}
                        name={`question${i}`}
                        title="Is this the correct answer?"
                        onChange={(e) => {
                          o.isCorrect = !!e.target.checked;
                          setQuestions([...questions]);
                        }}
                      />
                      <input
                        type="text"
                        value={o.value}
                        placeholder="Possible option"
                        onChange={(e) => {
                          o.value = e.target.value;
                          setQuestions([...questions]);
                        }}
                      />
                      <button
                        className="secondary inline"
                        onClick={() => {
                          // TODO: Is this sufficient to mutate the underlying question?
                          q.options.splice(j, 1);
                          setQuestions([...questions]);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  ))}
                  <div>
                    <button
                      className="secondary inline"
                      onClick={() => {
                        q.options.push({
                          value: "",
                          isCorrect: false,
                        });
                        setQuestions([...questions]);
                      }}
                    >
                      Add another option
                    </button>
                  </div>
                </div>
              </div>
            ))}

            <div>
              <button
                className="secondary inline"
                onClick={() => {
                  setQuestions([
                    ...questions,
                    {
                      prompt: "",
                      imageUrl: "",
                      details: "",
                      options: [{ value: "", isCorrect: false }],
                    },
                  ]);
                }}
              >
                Add another question
              </button>
            </div>
          </div>
        </div>
      )}

      {errorMessage && <div style={{ marginTop: "10px" }}>{errorMessage}</div>}
      <div style={{ marginTop: "10px" }}>
        <button type="button" onClick={saveChanges} disabled={isSubmitting}>
          {isSubmitting && <FontAwesomeIcon icon="circle-notch" spin={true} />}
          {props.assessment ? "Save Changes" : "Create"}
        </button>
        <button
          type="button"
          onClick={props.onClose}
          className="secondary"
          disabled={isSubmitting}
          style={{ marginLeft: "20px" }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};
