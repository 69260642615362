import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, MenuItem, SubMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { OrgContext } from "./common/OrgContext";
import { UserContext } from "./common/UserContext";
import { Organization, User, makeRequest } from "./common/api";
import { isUserCreator } from "./common/auth";

setTimeout(() => {
  const params = new URLSearchParams(window.location.search);
  const orgIdParam = params.get("organizationId");
  if (orgIdParam) {
    window.localStorage.setItem("organizationId", orgIdParam);
  }
}, 20);

const Header = styled.header`
  overflow-x: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
`;

const HeaderContent = styled.div`
  color: #000;
  font-size: 16px;
  padding: 15px 10px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: end;

  @media screen and (max-width: 400px) {
    padding-left: 20px;
  }
`;

const NavLink = styled(Link)`
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 500;

  text-decoration: none;
  cursor: pointer;

  margin-right: 20px;
  white-space: no-wrap;

  :focus,
  :hover,
  .selected {
    color: #b181ea;
  }

  .navLink:active {
    color: #a161da;
  }
`;

const MainNavLink = styled(NavLink)`
  margin-right: 40px;
  width: auto;
  @media screen and (max-width: 400px) {
    display: none;
  }
`;

const NavImg = styled.img`
  margin-right: 40px;
  height: 40px;
`;

const LogoutLink = styled.span`
  margin-left: 10px;
  color: #84c;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

export const NavBar = () => {
  const userContext = React.useContext(UserContext);
  const user = userContext?.user;

  const logout = async () => {
    await makeRequest("/api/logout", "POST");
    userContext?.logout();
    window.location.href = "/";
  };

  return (
    <Header>
      <HeaderContent>
        <a href="https://upliftcodecamp.com/" target="_blank">
          <NavImg src="/logo.png" />
        </a>
        <NavLink to="/enroll">Enroll</NavLink>
        {isUserCreator(userContext?.user) && (
          <NavLink to="/admin/dashboard">Dashboard</NavLink>
        )}
        {isUserCreator(userContext?.user) && (
          <NavLink to="/admin/config">Config</NavLink>
        )}

        <div
          style={{
            textAlign: "right",
            flexGrow: 1,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {user ? (
            <>
              <LogoutLink onClick={logout}>Logout</LogoutLink>
            </>
          ) : (
            <NavLink to="/login">Login </NavLink>
          )}
        </div>
      </HeaderContent>
    </Header>
  );
};

interface UserDropdownProps {
  user: User | null;
}
export const UserDropdown = (props: UserDropdownProps) => {
  const org = React.useContext(OrgContext);
  const [organizations, setOrganizations] = React.useState<
    Organization[] | null
  >(null);

  const fetchOrganizations = async () => {
    let orgs = organizations || [];
    for (let orgRole of props.user.orgRoles) {
      if (org && org !== "NONE" && orgRole.orgId === org._id) {
        orgs = [...orgs, org];
        setOrganizations(orgs);
      } else {
        const orgItem = await makeRequest(
          `/api/organizations/${orgRole.orgId}`,
          "GET"
        );
        orgs = [...orgs, orgItem];
        setOrganizations(orgs);
      }
    }
  };
  React.useEffect(() => {
    if (props.user && !organizations) {
      setOrganizations([]);
      fetchOrganizations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizations, props.user]);

  return (
    <Menu
      menuButton={
        <span style={{ cursor: "pointer" }}>
          {props.user.email} <FontAwesomeIcon icon="chevron-down" />
        </span>
      }
      portal={true}
      align="end"
      direction="bottom"
      position="initial"
    >
      <SubMenu label="Organizations">
        {props.user.orgRoles.map((role) => {
          const knownOrgs = [...(organizations || [])];
          if (org && org !== "NONE") {
            knownOrgs.push(org);
          }

          return (
            <MenuItem key={role.orgId}>
              <FontAwesomeIcon icon="check" />
              {knownOrgs.find((o) => o._id === role.orgId)?.name} - {role.role}
            </MenuItem>
          );
        })}
      </SubMenu>
    </Menu>
  );
};
