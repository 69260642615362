// Exports a full-featured data table component with custom rendering, sorting, filtering & more
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, MenuItem } from "@szhsin/react-menu";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  buildValueTableFacetMap,
  buildValueTableSubfacetMap,
  filterValueTableByFacets,
  IndexFacet,
  ValueTable,
} from "../faceting";
import {
  AttachmentLink,
  FlexRow,
  getTimestampString,
  getYearMonthDate,
  truncateInMiddle,
} from "../features/Utils";
import { justConfirm } from "./Modals";

export const SimpleStyledTable = styled.table`
  th {
    text-align: center;
  }
  th,
  td {
    padding: 5px;
  }
  tbody tr:nth-child(2n + 1) {
    background-color: #faf8ff;
  }
  input {
    width: 150px;
  }
`;

const SortButton = styled.button`
  && {
    color: rgba(0, 0, 0, 0.8);
    border: none;
    background: rgba(100, 105, 100, 0.1);
    margin: 0 0 0 5px;
    padding: 2px 5px;
  }
`;

const ModalWrapper = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(50, 50, 50, 0.9);
`;
const ModalBody = styled.div`
  padding: 50px;
  border-radius: 10px;
  background-color: white;
  position: relative;
  max-width: 600px;
  max-height: 600px;
`;
const ModalCloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export interface DataTableColumnDef {
  label: string;
  tooltip?: string;

  // If provided, will wrap the cell contents in a Link
  href?: (data: any) => string;

  // One of key or renderer must be provided
  key?: string;
  type?: "paragraph" | "date" | "timestamp" | "file" | "text" | "email" | "tel";

  renderer?: (data: any) => React.ReactNode;

  // If the renderer returns a ReactNode, you can still provide a SortText to enable sorting and filtering
  getSortText?: (data: any) => string;

  // If provided, will be used to sort the data.
  comparator?: (a: any, b: any) => number;

  hidden?: boolean; // Set to false to hide the column, ex for a particular user
}
export interface DataTableProps {
  columns: DataTableColumnDef[];
  data: any[];

  canExpand?: boolean;
  renderExpandedRow?: (data: any, index: number) => React.ReactNode;

  /** If provided, there will be a delete icon */
  onRowDelete?: (index: number) => void;

  canSort?: boolean;
  defaultSortKey?: string;
  defaultIsSortAscending?: boolean;

  canFilter?: boolean;
}
export const DataTable = (props: DataTableProps) => {
  const [modalText, setModalText] = React.useState("");
  const [expandedIndex, setExpandedIndex] = React.useState(-1);
  const [sortKey, setSortKey] = React.useState<string>(props.defaultSortKey);
  const [isSortAscending, setIsSortAscending] = React.useState(
    props.defaultIsSortAscending
  );
  const [selectedFacets, setSelectedFacets] = React.useState<IndexFacet[]>([]);

  const sortByClicked = (key: string) => {
    if (key === sortKey) {
      setIsSortAscending(!isSortAscending);
    } else {
      setSortKey(key);
    }
  };

  const removeFacet = (index: number) => {
    setSelectedFacets(selectedFacets.filter((facet) => facet.index !== index));
  };

  const facetClickedByIndex = (
    index: number,
    value: string | boolean,
    type: "Value" | "Existence" = "Value"
  ) => {
    const newFacets = selectedFacets.filter((f) => f.index !== index);
    newFacets.push({ index, value, type });
    setSelectedFacets(newFacets);
  };

  // Render all of the table content first, before sorting and filtering
  const valueTable: ValueTable = props.data.map((row, index) => {
    return {
      id: index + 1,
      rowData: row,
      renderedValues: props.columns.map((col) => {
        const values = {
          textContent: "",
          node: null as any,
          style: null as any,
        };

        if (col.renderer) {
          const rendered = col.renderer(row);
          if (
            typeof rendered === "string" ||
            typeof rendered === "number" ||
            typeof rendered === "boolean"
          ) {
            values.textContent = "" + rendered;
          } else {
            values.node = rendered;

            if (col.getSortText) {
              values.textContent = col.getSortText(row);
            }
          }
        } else if (col.key && row[col.key] !== undefined) {
          const cell = row[col.key];

          if (col.type === "file") {
            values.textContent = cell ? truncateInMiddle(cell.fileName) : "";
            values.node = cell && <AttachmentLink file={cell} />;
          } else if (col.type === "timestamp") {
            values.textContent = getTimestampString(cell);
          } else if (col.type === "date") {
            values.textContent = getYearMonthDate(cell);
          } else if (col.type === "paragraph" && cell) {
            values.textContent = cell.slice(0, 8);
            values.style = { cursor: "pointer" };
            // eslint-disable-next-line
            values.node = (
              <a onClick={() => setModalText(cell)}>{cell.slice(0, 8)}...</a>
            );
          } else {
            values.textContent = "" + cell;
          }
        }

        return values;
      }),
    };
  });

  // Filter a copy of the valueTable based on Facets
  let filteredValueTable = [...valueTable];
  if (selectedFacets.length > 0) {
    filteredValueTable = filterValueTableByFacets(
      filteredValueTable,
      selectedFacets
    );
  }

  // Sorting the table content based on the selected (or default) sort key
  const columns = props.columns.filter((col) => !col.hidden);
  if (sortKey) {
    const comparator = props.columns.find((p) => p.key === sortKey)?.comparator;
    if (comparator) {
      filteredValueTable.sort((a, b) => comparator(a.rowData, b.rowData));
      if (isSortAscending) {
        filteredValueTable.reverse();
      }
    } else {
      filteredValueTable.sort((a, b) => {
        const aVal = a.rowData[sortKey];
        const bVal = b.rowData[sortKey];
        if (aVal && !bVal) {
          return isSortAscending ? -1 : 1;
        } else if (bVal && !aVal) {
          return isSortAscending ? 1 : -1;
        } else if (aVal < bVal) {
          return isSortAscending ? -1 : 1;
        } else if (aVal > bVal) {
          return isSortAscending ? 1 : -1;
        }
        return 0;
      });
    }
  }

  const subFacets = buildValueTableSubfacetMap(valueTable, selectedFacets);

  const facetList = buildValueTableFacetMap(filteredValueTable);

  const renderFilterMenu = (
    column: DataTableColumnDef,
    columnIndex: number
  ) => {
    const subfacet = subFacets.find((f) => f.index === columnIndex);
    const facet = subfacet || facetList[columnIndex];
    const selectedFacet = selectedFacets.find((f) => f.index === columnIndex);
    if (!facet) {
      return null;
    }

    // Do not show the filtering menu if there are no supported values
    if (
      facet.type === "Existence" &&
      (facet.populatedCount === props.data.length || facet.populatedCount === 0)
    ) {
      return null;
    }

    return (
      <span style={{ flex: "0 0 auto" }}>
        <Menu
          menuButton={
            <SortButton
              className="inline secondary"
              style={{
                backgroundColor: !!selectedFacet ? "#7799FF" : "",
              }}
            >
              <FontAwesomeIcon
                icon={sortKey !== column.key ? "filter" : "filter"}
              />
            </SortButton>
          }
        >
          {subfacet && (
            <MenuItem onClick={() => removeFacet(columnIndex)}>
              Clear Filter: {subfacet.totalCount}
            </MenuItem>
          )}
          {facet.type === "Value" &&
            Array.from(facet.valueCounts.keys())
              .sort(
                (a, b) => facet.valueCounts.get(b) - facet.valueCounts.get(a)
              )
              .map((valueName: string) => {
                return (
                  <MenuItem
                    key={valueName || "No value"}
                    onClick={() => facetClickedByIndex(columnIndex, valueName)}
                  >
                    {selectedFacet?.value === valueName && (
                      <FontAwesomeIcon
                        icon="check"
                        style={{ marginRight: "10px" }}
                      />
                    )}
                    {valueName || "No value"}:{" "}
                    {facet.valueCounts.get(valueName)}
                  </MenuItem>
                );
              })}
          {facet.type === "Existence" && (
            <MenuItem
              onClick={() =>
                facetClickedByIndex(columnIndex, true, "Existence")
              }
            >
              {selectedFacet?.value === true && (
                <FontAwesomeIcon icon="check" style={{ marginRight: "10px" }} />
              )}
              Any value: {facet.populatedCount}
            </MenuItem>
          )}
          {facet.type === "Existence" && (
            <MenuItem
              onClick={() =>
                facetClickedByIndex(columnIndex, false, "Existence")
              }
            >
              {selectedFacet?.value === false && (
                <FontAwesomeIcon icon="check" style={{ marginRight: "10px" }} />
              )}
              No value: {facet.totalCount - facet.populatedCount}
            </MenuItem>
          )}
        </Menu>
      </span>
    );
  };

  return (
    <>
      {modalText && (
        <ModalWrapper onClick={() => setModalText("")}>
          <ModalBody onClick={e => {
            e.stopPropagation();
          }}>
            <ModalCloseButton onClick={(e) => {
              setModalText("");
              e.preventDefault();
              e.stopPropagation();
            }}>
              <FontAwesomeIcon icon="times" size="1x" />
            </ModalCloseButton>
            {modalText}
          </ModalBody>
        </ModalWrapper>
      )}
      <SimpleStyledTable>
        <thead>
          <tr>
            {props.canExpand && <th>Expand</th>}
            {columns.map((col, columnIndex) => (
              <th title={col.tooltip} key={col.label}>
                <FlexRow style={{ alignItems: "center" }}>
                  <span style={{ flex: "1 1 100%" }}>{col.label}</span>
                  {col.key && props.canSort && (
                    <span style={{ flex: "0 0 auto" }}>
                      <SortButton
                        className="inline secondary"
                        onClick={() => sortByClicked(col.key)}
                      >
                        <FontAwesomeIcon
                          icon={
                            sortKey !== col.key
                              ? "sort"
                              : isSortAscending
                              ? "sort-up"
                              : "sort-down"
                          }
                        />
                      </SortButton>
                    </span>
                  )}
                  {props.canFilter && renderFilterMenu(col, columnIndex)}
                </FlexRow>
              </th>
            ))}
            {props.onRowDelete && <th>Tools</th>}
          </tr>
        </thead>
        <tbody>
          {filteredValueTable.map((row, rowIndex) => {
            return (
              <React.Fragment key={row.id}>
                <tr>
                  {props.canExpand && (
                    <td>
                      <button
                        className={
                          "inline " +
                          (rowIndex === expandedIndex ? "" : "secondary")
                        }
                        onClick={() => {
                          setExpandedIndex(
                            rowIndex === expandedIndex ? -1 : rowIndex
                          );
                        }}
                      >
                        <FontAwesomeIcon
                          icon={rowIndex === expandedIndex ? "minus" : "plus"}
                        />
                      </button>
                    </td>
                  )}
                  {columns.map((col, columnIndex) => {
                    const contentValue = row.renderedValues[columnIndex];

                    let content = contentValue.node || contentValue.textContent;

                    if (col.href) {
                      const to = col.href(row.rowData);
                      if (to) {
                        content = <Link to={to}>{content}</Link>;
                      }
                    }

                    return (
                      <td key={"" + columnIndex} style={contentValue.style}>
                        {content}
                      </td>
                    );
                  })}
                  {props.onRowDelete && (
                    <td>
                      <button
                        className="inline secondary"
                        title="Delete row"
                        onClick={() => {
                          const ok = justConfirm(
                            "Are you sure you want to remove this row?"
                          );
                          if (ok) {
                            props.onRowDelete?.(rowIndex);
                          }
                        }}
                      >
                        <FontAwesomeIcon icon="trash" />
                      </button>
                    </td>
                  )}
                </tr>
                {rowIndex === expandedIndex && (
                  <>
                    <tr>
                      <td colSpan={100}>
                        {props.renderExpandedRow(row.rowData, rowIndex)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={100}></td>
                    </tr>
                  </>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </SimpleStyledTable>
    </>
  );
};
