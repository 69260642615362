import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "./App.css";
import { NavBar } from "./NavBar";
import { UserContext } from "./common/UserContext";
import {
  Batch,
  IS_ADMIN_REF,
  Organization,
  User,
  makeRequest,
} from "./common/api";
import { AdminConfigPage } from "./pages/Admin/AdminConfigPage";
import { ApplicantDashboardPage } from "./pages/Admin/ApplicantDashboardPage";
import { ApplicationDetailsPage } from "./pages/ApplicationDetailsPage";
import { ApplyPage } from "./pages/ApplyPage";
import { HomePage } from "./pages/HomePage";
import { LoginPage } from "./pages/LoginPage";

import { BatchesContext } from "./common/BatchesContext";
import { AssessmentPage } from "./pages/AssessmentPage";
import { BatchDetailsPage } from "./pages/BatchDetailsPage";

// Initializes the minimal font-awesome icon set
import { OrgContext, OrgIdContext } from "./common/OrgContext";
import { isUserAdmin } from "./common/auth";
import { Library } from "./common/components/Icons";
import {
  captureQueryParams,
  setupAnalytics,
} from "./common/features/Analytics";
import { ApplyBatchPage } from "./pages/ApplyBatchPage";
import { ApplyOrgPage } from "./pages/ApplyOrgPage";
import { PaymentsPage } from "./pages/PaymentsPage";
import { PasswordResetPage } from "./pages/Utility/PasswordResetPage";
Library.noop(); // Hackery because Michael doesn't know how to make modules work properly in TypeScript

export default function App() {
  const [user, setUser] = React.useState<User | null>(null);
  React.useEffect(() => {
    makeRequest("/api/user", "GET").then((u: User) => setUser(u));
  }, []);

  const [batches, setBatches] = React.useState<Batch[] | null>(null);
  React.useEffect(() => {
    makeRequest("/api/batches", "GET").then((data) => {
      setBatches(data);
    });
  }, []);

  const [organization, setOrganization] = React.useState<
    Organization | "NONE" | null
  >(null);

  React.useEffect(() => {
    captureQueryParams();

    makeRequest(`/api/organizationForCurrentDomain`, "GET").then(
      (org) => {
        if (org) {
          if (document.title === "Apply now!") {
            document.title = "Apply to join " + org.name;
          }
          setOrganization(org);
          setupAnalytics(org);
        } else {
          setOrganization("NONE");
        }
      },
      (err) => {
        setOrganization("NONE");
        // No-op
      }
    );
  }, []);

  React.useEffect(() => {
    if (user && organization && organization !== "NONE") {
      IS_ADMIN_REF.isAdmin = isUserAdmin(user, organization._id);
    }
  }, [user, organization]);

  return (
    <Router>
      <UserContext.Provider
        value={{ user, setUser, logout: () => setUser(null) }}
      >
        <OrgContext.Provider value={organization}>
          <OrgIdContext.Provider
            value={organization !== "NONE" && organization && organization._id}
          >
            <BatchesContext.Provider value={{ batches, setBatches }}>
              <NavBar></NavBar>
              <Switch>
                <Route path="/login">
                  <LoginPage />
                </Route>
                <Route path="/signup">
                  <LoginPage startOnSignup={true} />
                </Route>
                <Route path="/applications/:applicationId/assessments/:assessmentId">
                  <AssessmentPage />
                </Route>
                <Route path="/application/:applicationId">
                  <ApplicationDetailsPage />
                </Route>
                <Route path="/batch/:batchId">
                  <BatchDetailsPage />
                </Route>
                <Route path="/apply/:orgId/:batchId">
                  <ApplyBatchPage />
                </Route>
                <Route path="/apply/:orgId">
                  <ApplyOrgPage />
                </Route>
                <Route path="/apply">
                  <ApplyPage />
                </Route>
                <Route path="/enroll">
                  <ApplyPage />
                </Route>
                <Route path="/admin/dashboard">
                  <ApplicantDashboardPage />
                </Route>
                <Route path="/admin/config">
                  <AdminConfigPage />
                </Route>
                <Route path="/payments">
                  <PaymentsPage />
                </Route>
                <Route path="/passwordReset">
                  <PasswordResetPage />
                </Route>
                <Route path="/">
                  <HomePage />
                </Route>
              </Switch>
            </BatchesContext.Provider>
          </OrgIdContext.Provider>
        </OrgContext.Provider>
      </UserContext.Provider>
    </Router>
  );
}
